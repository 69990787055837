.select {
  width: 300px;
  position: absolute !important;
  top: 9px;
  left: 430px;
}

.checkboxContainer {
  position: absolute;
  z-index: 1;
  top: 13px;
  right: 15px;
}

.checkboxContainer input[type="checkbox"]:disabled {
  border: 1px solid var(--smoke);
  cursor: not-allowed;
}

.disabled {
  cursor: not-allowed;
}

.quickAdd {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  z-index: 10;
}

.quickAdd:hover svg path {
  fill: var(--runn-blue);
}

.quickAdd svg {
  height: 28px;
  width: 28px;
}

.fakeRoleDropdown {
  width: 300px;
  position: absolute !important;
  top: 9px;
  left: 430px;
  z-index: 21;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  min-height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 8px;
  padding-top: 0px;
  color: black;
}

.fakeRoleDropdown svg {
  color: hsl(0, 0%, 80%);
  fill: hsl(0, 0%, 80%);
}

.fakeRoleText {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--midnight);
  font-size: 13px;
  gap: 6px;
  display: flex;
  align-items: center;
}

.fakeRoleText svg path {
  color: var(--slate);
  fill: var(--slate);
}

.fakeRoleDisabled svg path,
.fakeRoleDisabled span {
  color: var(--smoke);
  fill: var(--smoke);
}

.placeholderCreateCount {
  padding: 2px 8px;
  border: 1px solid var(--smoke);
  border-radius: 2px;
  height: 30px;
  width: 35px;
  margin-left: 5px;
  text-align: center;
}

input.placeholderCreateCount::-webkit-outer-spin-button,
input.placeholderCreateCount::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.placeholderCreateCount[type="number"] {
  -moz-appearance: textfield;
}

.memberText {
  color: var(--shadow);
  position: absolute;
  left: 350px;
}

.addNewPlaceholderButton {
  position: absolute;
  top: 45px;
  margin-left: 430px;
  cursor: pointer;
  z-index: 31;
}
