.container {
  display: flex;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  white-space: nowrap;
  margin-left: 8px;
}

.colorIcon {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 10px;
}

.square {
  border-radius: 0px;
}
