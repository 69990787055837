.container {
  height: 30px;
  display: flex;
}

.buttonGroup {
  margin-right: 10px;
  height: 30px;
}

.dropdownButton {
  min-width: 115px;
}

.button {
  font-size: 13px;
  font-weight: 600;
  padding: 0 15px;
  color: var(--slate) !important;
}

.button:hover {
  color: var(--runn-blue) !important;
}

.button svg path {
  fill: var(--slate) !important;
}

.button:hover svg path {
  fill: var(--runn-blue) !important;
}
