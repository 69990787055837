.container {
  padding: 20px 0;
  border-bottom: 1px solid var(--snow);
}

.container textarea {
  width: 100%;
  max-width: 100%;
  min-height: 83px;
  border: 1px solid var(--winter);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  line-height: 20px;
}

.container textarea::placeholder {
  color: var(--smoke);
}

.container textarea:focus {
  border-color: var(--runn-blue);
}

.container .visuallyHidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.footer > button {
  margin-left: 7px;
}
