.iconContainer {
  display: flex;
  align-items: center;
}

.labelContainer {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  grid-gap: var(--spacing-xxs);
}

.timeoffWithNote {
  margin-right: var(--spacing-tiny);
}
