.select:not(:last-child) {
  margin-bottom: 10px;
}

.newProjectLink {
  font-weight: 500;
  text-decoration: underline;
}

.nonbillableTooltip {
  max-width: 265px;
}

.oneColumnWrapper {
  margin-bottom: 15px;
}

.twoColumnWrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 20px;
  margin-bottom: 15px;
}

.oneColumnWrapper input,
.twoColumnWrapper input {
  margin-bottom: 0px;
}

.checkboxContainer {
  display: inline-flex;
  margin-bottom: 10px;
}

.checkboxLabel {
  margin-top: 8.5px;
  margin-left: 8px;
}

.checkboxTick {
  top: auto;
}

.closed {
  height: 0px;
  opacity: 0;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.open {
  height: 79px;
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.projectName {
  display: flex;
  align-items: flex-end;
}

.projectName > *:last-child {
  margin-left: var(--spacing-xs);
}

.checkboxesContainer {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 8px;
}
