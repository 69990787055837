.tooltip {
  display: inline-block !important;
}

.wrapper {
  padding: 30px;
}

.buttonContainer {
  display: flex;
  column-gap: var(--spacing-xs);
  margin: 20px 0;
}

/* Prevent tooltip containers taking full width and missing target */
.buttonContainer > :global(.bp5-popover-target) {
  width: fit-content;
}

.showButtonContainer {
  margin: 20px 0;
}

.sectionHeading {
  color: var(--shadow);
  font-size: 12px;
  margin-top: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--winter);
}
