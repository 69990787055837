.container {
  z-index: 9998;
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  left: 0;
}

.messageBox {
  margin-top: 7rem;
  width: 22rem;
  box-shadow:
    0 2px 3px 0 rgba(0, 0, 0, 0.05),
    0 1px 0 0 #e1e4eb,
    0 1px 0 0 var(--winter);
  padding: 1rem 1.7rem 1rem 1rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  grid-gap: 10px;
  z-index: 9999;
}

.textBold {
  font-weight: 600;
  margin-bottom: 0.3rem;
}
