/* 
  Override toastify's color variables to match our app.
  See: https://fkhadra.github.io/react-toastify/how-to-style/#override-css-variables
*/
:root {
  --toastify-color-dark: var(--midnight);
  --toastify-color-info: #91b8ff;
  --toastify-color-success: var(--success);
  --toastify-color-warning: var(--warning);
  --toastify-color-error: var(--alert);

  --toastify-text-color-light: var(--midnight);
  --toastify-color-progress-dark: var(--runn-purple);

  --toastify-font-family: var(--runn-font-family);
}

.toastContainer {
  margin-bottom: 50px;
}

.message {
  font-weight: 600;
}

.toastContent {
  font-size: 13px;
}

.toastContent > *:not(.last-child) {
  margin-bottom: 2px;
  overflow-wrap: anywhere; /* handles long text with no breaks i.e. "this_is_really_long_with_no_breaks_why_oh_why" */
  white-space: break-spaces;
}

.toastContent a {
  text-decoration-line: underline;
  opacity: 0.8;
  font-weight: 600;
  margin-right: var(--spacing-xs);
}

.toastContent a:hover {
  opacity: 1;
}

:global(.Toastify__toast-theme--light a) {
  color: var(--runn-blue);
}

:global(.Toastify__toast-theme--dark a),
:global(.Toastify__toast-theme--colored:not(.Toastify__toast--default) a) {
  color: #ffffff;
}

:global(.Toastify__toast-icon) {
  align-self: start;
  -webkit-margin-end: var(--spacing-xs);
}

/* Colored Theme -- don't default to colored variables above because they are too light */
:global(.Toastify__toast-theme--colored) svg {
  fill: #ffffff;
}

:global(
    .Toastify__toast-theme--colored.Toastify__toast--default
      .Toastify__close-button
      > svg
  ) {
  fill: var(--slate);
}

:global(
    .Toastify__progress-bar-theme--colored.Toastify__progress-bar--default
  ) {
  background: rgba(var(--runn-blue_rgb), 0.8);
}

:global(.Toastify__toast-theme--colored.Toastify__toast--info) {
  background: var(--sea-blue);
}

:global(.Toastify__toast-theme--colored.Toastify__toast--error) {
  background: var(--alert-dark);
}

:global(.Toastify__toast-theme--colored.Toastify__toast--success) {
  background: var(--success-dark);
}

/* The default toastify transitions are too slow let's use our own */
@keyframes slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

.slideRightEnter {
  animation: slideInRight 0.3s ease-out both;
}

.slideRightExit {
  animation: slideOutRight 0.3s ease-out both;
}
