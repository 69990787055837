.row {
  background-color: #fff;
  position: sticky;
  top: 0px;
  align-items: stretch;
  z-index: 10;
  border-bottom: 1px solid var(--cloud);
  transition: all ease-in-out 0.3s;
}

.backgroundBorderBottomOnly {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='1' height='1'><rect fill='rgba(213,216,228,1)' x='0' y='0' width='1' height='1'/></svg>"),
    url("#");
  background-repeat: repeat-x, no-repeat;
  background-position:
    bottom right,
    0 0;
}

.collapse {
  transition: height 500ms;
}

.rowExpanded {
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
  transition: all ease-in-out 0.3s;
  border: none;
}

.personDetails {
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
}

.title a {
  color: var(--midnight);
}

.title a:hover {
  color: var(--runn-blue);
}
