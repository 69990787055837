.container {
  padding: 20px;
  border: 1px solid var(--winter);
}

.header {
  background: var(--ice);
  border-radius: 3px 3px 0px 0px;
  padding: 15px 20px;
  display: grid;
  border: 1px solid var(--winter);
  border-bottom: none;
}

.statusFilter {
  display: flex;
  align-items: center;
  justify-self: flex-end;
}

.statusFilter span {
  color: var(--shadow);
  font-weight: 500;
  padding-right: 15px;
}

.noResults {
  color: var(--smoke);
  text-align: center;
}
