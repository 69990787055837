.effort {
  display: inline-flex;
}

.duration {
  padding: 0;
  width: 60px;
  border: 1px solid var(--smoke);
  border-radius: var(--rounded-sm);
  border-top-right-radius: var(--rounded-none);
  border-bottom-right-radius: var(--rounded-none);
  border-right: none !important;
}

.duration:focus-visible {
  border-color: var(--runn-blue);
  box-shadow: 1px 0 0 0 var(--runn-blue);
  position: relative;
  z-index: 1;
}

.tooltip {
  display: flex;
}

.tooltipSpacing {
  margin-right: var(--spacing-xxs);
}

.tooltipLineBreak {
  white-space: pre-wrap;
  text-align: center;
}

.noSelect {
  border-right: 1px solid var(--smoke) !important;
  width: 105px;
}

.noSelect:focus-visible {
  box-shadow: none;
  border-right: 1px solid var(--runn-blue) !important;
}
