.selectorListItem {
  display: flex;
  align-items: center;
  min-height: 49px;
  border-bottom: 1px solid var(--cloud);
  padding: 0 10px;
  position: relative;
  width: 100%;
}

.selectorListItem:not(.disabled):not(.placeholder):hover {
  background-color: var(--ice);
  cursor: pointer;
}

/* .selectorListItem:not(.disabled):not(.placeholder):hover {
  cursor: pointer;
} */

.selectorListItem.disabled {
  opacity: 0.4;
}

.selectorListItem.placeholder {
  background-color: rgba(104, 153, 241, 0.1);
}

.selectorListItem.tall {
  min-height: 80px;
}

.name {
  width: 50%;
  max-width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 11px;
}

.rowAction {
  margin-left: auto;
}

.select {
  max-width: 170px;
  width: 30%;
  position: absolute !important;
  top: 8px;
  right: 100px;
}
