.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.capacityIndicator {
  height: 100%;
  display: flex;
  position: relative;
  border-top: 4px solid transparent;
  color: #fff;
}

.full {
  background-color: #687ce3;
}

.nearlyFull {
  background-color: #b1bbf1;
  color: #2037c0;
}

.partiallyFull {
  background-color: #e4e8fb;
  color: var(--runn-blue);
}

.completelyFree {
  background-color: #e8fbec;
  color: #52965b;
  border-color: #8acd96;
}

.assignedNonWorkingDay,
.overbooked {
  background-color: #27387e;
  border-color: #f53f59;
}

.nonWorkingDay {
  background-color: var(--ice);
}

.conflict {
  border-top: 4px solid var(--warning);
}

.capacityAmount {
  font-size: 12px;
  font-weight: 600;
  height: 100%;
  width: 100%;
  display: flex;
  padding: 6px 8px;
  white-space: nowrap;
  overflow: hidden;
  align-items: flex-end;
}

.noCapacity {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  padding: 6px 8px;
}

.noContract,
.timeOff {
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 35%,
    var(--winter) 19%,
    var(--winter) 50%
  );
  background-size: 4px 4px;
}

.label {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: var(--slate);
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  line-height: 10px;
  padding: 4px 10px;
  border: 1px solid #fff;
  white-space: nowrap;
  border-radius: 12px;
}

.timeOffIcon {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  padding: 0;
}

.warning {
  cursor: pointer;
  margin: 2px 0 0 auto;
}

.warning:hover svg path:first-of-type {
  fill: black;
}
