.container {
  margin-top: 25px;
}

.container summary {
  font-size: 12px;
  font-weight: 600;
}

.description {
  margin: 15px 0 25px 0;
  font-size: 13px;
}

.rows {
  display: grid;
  grid-gap: 10px;
  min-width: 200px;
  margin-bottom: 25px;
  margin-top: 15px;
}

.row {
  display: grid;
  align-items: end;
  grid-template-columns: 1fr 1fr 20px;
  grid-gap: 10px;
}

.rowHeader {
  font-weight: 500;
  color: var(--slate);
  font-size: 11px;
}

.showInPlanner {
  display: flex;
  align-items: flex-end;
  gap: var(--spacing-xxs);
}

.showInPlanner label {
  margin-bottom: 0;
}

.delete {
  margin-bottom: 8px;
  cursor: pointer;
}

.delete:focus {
  outline: none;
}

.delete:focus-visible svg path,
.delete:hover svg path {
  fill: var(--slate);
}
