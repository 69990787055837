.createButton {
  width: 76px;
}

.omniButton {
  color: var(--midnight);
  font-size: 13px;
}

.omniButton:global(.bp5-menu) {
  min-width: 120px;
}

.omniButton :global(.bp5-menu-item) {
  padding: 10px 15px;
}
