.footer {
  justify-content: space-between !important;
}

.link {
  font-weight: 500;
  text-decoration: none;
}

.icon {
  padding-top: var(--spacing-xxs);
}

.tooltip {
  display: flex;
  grid-gap: var(--spacing-xxs);
  align-items: center;
}

.tooltipLineBreak {
  white-space: pre-wrap;
}

.portal > div {
  z-index: 15;
}
.backdrop {
  z-index: 15;
}
/* Ensure the create account item is hidden in the error state because it doesn't navigate away from the page */
:global(body:has(.error-modal-portal) [role="menuitem"]#create-account) {
  display: none;
}
