.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tabContainer {
  padding: 0;
}

.personTabs :global(.bp5-tab-list.bp5-large) {
  background-color: #fff !important;
  border-bottom: 1px solid var(--winter);
  padding-top: var(--spacing-default);
}

.personTabs :global(.bp5-tab) {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1em;
}

.personTabs :global(.bp5-tab[aria-selected="true"]) {
  color: var(--runn-blue);
}

/* To align with Blueprint Icon buttons */
.dashboardBtn {
  margin: 0 -7px;
}

.actionBtn:global(.bp5-button.bp5-intent-primary.bp5-outlined):hover svg path,
.dashboardBtn:global(.bp5-button.bp5-intent-primary.bp5-outlined):hover
  svg
  path {
  fill: var(--runn-blue) !important;
}

.detailsContainer {
  padding: var(--spacing-large);
}

.detailsValue {
  display: block;
  margin-bottom: var(--spacing-medium);
  font-size: 13px;
  overflow-wrap: break-word;
}

.skillsContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: var(--spacing-medium);
  min-height: 40px;
}

.skillsContainer > *:not(:last-child) {
  margin-right: 10px;
}

.tagsContainer {
  margin-bottom: var(--spacing-medium);
}

.footer {
  justify-content: flex-end;
}

.empty {
  color: var(--smoke);
  text-align: center;
  padding: var(--spacing-large);
}

.contractContainer {
  padding: var(--spacing-large);
}

.contractContainer:not(:last-child) {
  border-bottom: 1px solid var(--winter);
}

.contractContainer > div {
  display: flex;
  margin-bottom: var(--spacing-default);
  color: var(--midnight);
}

.contractContainer > div:last-of-type {
  margin-bottom: 0;
}

.detailsContainer h4,
.contractContainer h4 {
  font-size: 13px;
  font-weight: 700;
  width: 170px;
  margin: 0;
  padding-bottom: var(--spacing-xxs);
}

.contractContainer p {
  font-size: 13px;
  overflow-wrap: break-word;
  margin: 0;
  width: 170px;
}

.rosteredDaysWrapper {
  min-width: 130px;
  display: grid;
  grid-auto-flow: dense;
  grid-gap: 3px;
  grid-template-columns: repeat(auto-fill, minmax(0, 20px));
}

.rosteredDay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--runn-blue);
  text-transform: capitalize;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  color: white;
  font-size: 11px;
  font-weight: 500;
}

.rosteredDay.deselected {
  background-color: var(--cloud);
  color: var(--shadow);
}

.contractLabel {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--slate);
  letter-spacing: 0.1em;
  margin-top: 0;
}
