.emptyStateWrapper {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emptyStateWrapper p {
  text-align: center;
  line-height: 2.5;
  margin: 0;
}

.tangramsWrapper svg {
  margin: 0 25px 25px 0;
}

.button {
  margin: 10px 0;
}

.text {
  font-weight: 600;
  font-size: 18px;
}

.secondaryText {
  font-size: 14px;
  font-weight: 500;
}

.accordionWrapper {
  display: flex;
  align-items: center;
  height: 181px;
  gap: 23px;
  color: var(--slate);
  padding: 29px 53px;
  justify-content: center;
  flex-direction: row;
}

.accordionText {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.accordionSVG {
  margin: 0px -25px -25px 0;
}
