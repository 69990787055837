.wrapper {
  position: relative;
}
.link {
  display: flex;
  align-items: center;
  position: absolute;
  top: 8px;
  right: 0px;
  color: var(--slate);
  font-size: 11px;
}

.link:hover {
  color: var(--runn-blue) !important;
}

.link span {
  margin-left: 3px;
}
