.ActionPillContainer {
  cursor: pointer;
  user-select: none;
}

.PillContainer {
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  user-select: none;
  -webkit-user-select: none;
  margin-left: 0.5px;
}

.isDisabled {
  cursor: not-allowed;
  user-select: all;
}

.overlay {
  position: fixed;
  background-color: transparent;
  z-index: 20;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: grabbing;
}

.splitterContainer {
  width: 100%;
  position: absolute;
  z-index: 25;
}

.resizer {
  position: absolute;
  width: 10px;
  height: 100%;
  z-index: 9;
  cursor: ew-resize;
}

.editFormTarget {
  left: 50%;
  top: 10px;
  position: absolute;
}

.assignmentToolTipContainer {
  width: 100%;
}
.assignmentToolTipContainer > span {
  width: 100%;
}

.editFormPortalWrapper :global(.bp5-popover-arrow-fill) {
  fill: var(--snow);
}

.nonWorkingDay .overlay {
  cursor: not-allowed;
}

.conflictMenuHeading {
  background: var(--highlight);
  font-weight: 600;
  padding: 5px 7px;
  border-top: 1px solid #ece5da;
  border-bottom: 1px solid #ece5da;
  display: flex;
  align-items: center;
  color: var(--midnight);
  font-size: 13px;
}
