.container {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.label {
  color: var(--midnight);
  cursor: pointer;
  height: 16px;
  margin-left: var(--spacing-xxs);
  font-size: 13px;
}

.checkboxInput {
  appearance: none;
  display: inline-block;
  margin-top: 1px;
  height: 20px;
  width: 20px;
  border: 1px solid var(--smoke);
  background: var(--ice);
  border-radius: var(--rounded);
  cursor: pointer;
  transition: background 100ms;
}

.checkboxInput:indeterminate,
.checkboxInput:checked {
  border: 1px solid var(--runn-blue);
  background: var(--runn-blue);
}

.checkboxInput:focus {
  outline: var(--runn-blue) auto 5px;
}

.checkboxMinus {
  position: absolute;
  top: 3px;
  left: 2px;
  display: none !important;
  pointer-events: none;
}

.checkboxInput:indeterminate ~ .checkboxMinus {
  display: block !important;
}

.checkboxTick {
  position: absolute;
  top: 5px;
  left: 4px;
  display: none;
  pointer-events: none;
}

.checkboxInput:checked ~ .checkboxMinus {
  display: none !important;
}

.checkboxInput:checked ~ .checkboxTick {
  display: block !important;
}

.checkboxInput:disabled {
  border: 1px solid transparent;
  cursor: not-allowed;
  opacity: 40%;
}
