.container {
  height: 100%;
  display: flex;
  align-items: center;
}

.warning {
  cursor: pointer;
  margin-top: 4px;
}

.warning:hover svg path:first-of-type {
  fill: var(--midnight);
}

.warningToolTip {
  min-width: 300px;
}
