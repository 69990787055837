.selectorFooter {
  background-color: var(--snow);
  height: 50px;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  width: 100%;
  align-items: center;
  padding: 0 15px;
  border-top: 1px solid var(--cloud);
}
