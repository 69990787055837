.withIcon {
  display: flex;
  gap: 6px;
  align-items: center;
}

.withIcon span {
  height: 14px;
}

.withIcon svg path {
  color: var(--slate) !important;
  fill: var(--slate);
}

.disabled,
.disabled svg path {
  color: var(--smoke) !important;
  fill: var(--smoke);
}
