.formWrapper {
  display: grid;
  grid-row-gap: 15px;
}

.formWrapper input {
  margin: 0;
}

.formWrapper label {
  margin-bottom: var(--spacing-xs);
}

.twoColumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.costStatus {
  margin-top: 2px;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 500;
  color: var(--slate);
}

.title {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  padding-left: 24px;
  padding-bottom: 16px;
  margin-top: 30px;
  margin-bottom: 0px;
  color: var(--slate);
  border-bottom: 1px solid var(--winter);
  letter-spacing: 0.1em;
}

.formWrapper :global(.bp5-numeric-input) {
  width: 100% !important;
}

.tooManyPlaceholdersMessage {
  display: flex;
  gap: 8px;
  padding-top: 8px;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--slate);
}
