.container {
  display: flex;
  flex-grow: 1;
}

.graphContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.timelineContainer {
  display: flex;
}

.timeline {
  width: calc(100% - 350px);
}

.sidebar {
  width: var(--planner-side-width);
  background: var(--ice);

  /* 50px right padding to match Chart.yAxis width */
  padding: 12px 50px 12px 12px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info {
  color: var(--midnight);
  font-size: 14px;
}

.info b {
  font-weight: 600;
  margin-bottom: 5px;
}

/* MainEmpty styling */
.chart {
  flex: 1;
  box-shadow: inset 3px 0 1px -1px var(--winter);
  display: flex;
  position: relative;
}

.alert {
  margin-top: 5px;
}

.empty .graphContainer {
  opacity: 0.4;
}

.empty .sidebar {
  padding-right: var(--spacing-small);
}
