.plannerCalendar {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.footer {
  width: 100%;
  background-color: var(--ice);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
}

.statsWrapper {
  display: flex;
  align-items: baseline;
  padding: 15px 20px 10px;
  justify-content: center;
}

.statsWrapper.spaceBetween {
  justify-content: space-between;
}

.stats {
  display: flex;
}

.stats > *:not(:last-child),
.statsWrapper > *:not(:last-child) {
  margin-right: var(--spacing-xs);
}

.statsWrapper > * {
  margin-bottom: var(--spacing-small);
}

.stats label,
.statsWrapper label {
  display: block;
  margin-bottom: var(--spacing-xxs);
  color: var(--midnight);
  font-weight: 600;
  font-size: 12px;
  letter-spacing: -0.01em;
}

.stats input {
  height: 40px;
  text-align: center;
  font-size: 14px !important;
  font-weight: 500;
  color: var(--midnight);
  box-shadow: none;
  border: 1px solid var(--smoke);
  padding: 5px;
  margin: 0 !important;
}

.phasePicker {
  flex-grow: 1;
}

.phaseOption {
  display: flex;
  align-items: center;
}

.phaseOptionText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.phaseColouredDot {
  width: 15px;
  height: 15px;
  min-width: 15px;
  border-radius: var(--rounded-full);
  margin-right: 8px;
}

.workingDaysInput input {
  max-width: 55px;
}

.hoursInput {
  max-width: 75px;
}

.totalHours {
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--midnight);
}

.buttons {
  margin-left: auto;
  display: flex;
}

.buttons button {
  margin-left: 13px;
}

.buttons button:disabled {
  cursor: not-allowed;
}

.innerWrapper {
  width: 100%;
  padding: 0 20px;
}

.nonBillableCheckbox {
  display: flex;
  justify-content: center;
  align-self: center;
  margin: 0 11px;
  height: 100%;
}

.stats input.dateError {
  border: 1px solid var(--alert-dark);
  color: var(--alert-dark);
}

.flex {
  display: flex;
}

.errorLabel {
  color: var(--alert-dark) !important;
  margin-top: var(--spacing-xxs);
}

.fullPickerNoteWrapper {
  padding: 0 var(--spacing-medium) var(--spacing-small);
}

.fullPickerNoteWrapper summary {
  margin-top: var(--spacing-small);
}

.fullPickerNoteDivider {
  margin: 0px;
}

.stacking {
  display: block;
}

.subLabel {
  font-size: 12px;
  color: var(--slate);
  font-weight: 500;
  margin-top: 4px;
}
