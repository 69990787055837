.milestoneForm {
  padding: 15px 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 18px;
  font-weight: 500;
}

.date {
  color: var(--slate);
}

.body {
  display: flex;
  margin-top: 10px;
}

.formContainer {
  width: 285px;
  padding-top: 10px;
}

.formContainer textarea {
  border: 1px solid var(--winter);
  width: 100%;
  border-radius: 2px;
  padding: 11px;
}

.noteInput {
  resize: none;
  height: 140px;
}

.noteInputSmall {
  height: 55px;
}

.formContainer textarea::placeholder {
  color: var(--slate);
}

.footer {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: flex-end;
}

/* Icons */
.iconContainer {
  list-style-type: none;
  padding: 0 0 10px 0;
  display: flex;
}

.iconContainer svg {
  padding: 7px !important;
}

.iconDiv {
  margin-right: 10px;
}

.icon {
  cursor: pointer;
}

.icon:hover {
  background-color: var(--runn-midnight-blue);
}

/* Datepicker overrides - the rest are in DatePicker.module.css  */
.milestoneForm :global(.DayPicker) {
  margin: 0 0 0 20px;
}

.milestoneForm :global(.DayPicker-Day) {
  height: 35px;
  width: 35px;
  font-size: 12px;
  border-color: var(--winter);
}

.milestoneForm :global(.DayPicker-Day):hover,
.milestoneForm :global(.DayPicker-Day):focus {
  background-color: var(--cloud);
}

.milestoneForm :global(.DayPicker-Weekday) {
  font-size: 12px;
  padding: 0px 0 4px;
}
