.pageControls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  background: var(--ice);
  border-top: 1px solid var(--cloud);
}

.pageControls.disabled {
  opacity: 0.5;
  pointer-events: none;
}
