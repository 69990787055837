.phaseName {
  display: flex;
  min-width: 0;
  margin: 2px 3px;
  align-items: center;
  padding: 2px 8px;
  color: #fff;
  border-radius: 10px 10px 10px 0px;
}

.phaseName span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 11px;
  font-weight: 500;
}
