.tabs :global(.bp5-tab) {
  color: var(--shadow);
}

.tabs :global(.bp5-tab-indicator) {
  background-color: var(--runn-blue);
  height: 2px;
}

.tabs :global(.bp5-tab-list) {
  padding-left: 0;
  color: var(--slate);
  font-weight: 500;
}

.tabs :global(.bp5-tab-list > :not(:last-child)) {
  margin-right: var(--spacing-small);
}

.tabs :global(.bp5-tab-list.bp5-large) {
  height: 69px;
  padding: 30px 0 0 30px;
  background-color: var(--ice);
  position: sticky;
  top: 0;
  z-index: 15;
}

.tabs :global(.bp5-tab-list:not(.bp5-large) > .bp5-tab) {
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.4px;
}

.tabs :global(.bp5-tab[aria-selected="true"]),
.tabs :global(.bp5-tab:not([aria-disabled="true"]):hover),
.tabs :global(.bp5-tab-list:not(.bp5-large) > .bp5-tab[aria-selected="true"]),
.tabs
  :global(
    .bp5-tab-list:not(.bp5-large) > .bp5-tab[aria-selected="true"]:hover
  ) {
  color: var(--midnight);
}

.tabs :global(.bp5-tab-panel) {
  margin-top: 0;
}

.rightTabs :global(.bp5-tab-list.bp5-large) {
  padding: 30px 30px 0 0;
  justify-content: flex-end;
}
