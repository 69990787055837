.plannerCalendarRow {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
}

.warning {
  position: absolute;
  width: 30px;
}

.warningToolTip {
  max-width: 300px;
}
