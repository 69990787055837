.radioGroup {
  max-width: 550px;
  font-size: 13px;
  position: relative;
}

.radioGroup :global(.bp5-label):first-of-type {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 20px;
}

.radioGroup :global(.bp5-radio) {
  display: inline-block;
}

.radioGroup :global(.bp5-radio):last-of-type {
  margin: 30px 0;
}

.radioTitle {
  display: inline-block;
  font-weight: 600;
  margin-bottom: 5px;
}

.radioGroup .radioText {
  color: var(--slate);
  line-height: 1.5;
  margin: 0;
}

.daterPickerWrapper {
  margin-left: 28px;
  display: flex;
  align-items: center;
}

.daterPickerWrapper p {
  color: var(--slate);
  font-weight: 500;
  margin: 0;
}

.dateInput {
  margin-left: 10px;
}

.dateInput label {
  margin: 0;
}
