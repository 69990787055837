.midnightLink,
.midnightLink:hover,
.midnightLink:focus {
  color: var(--midnight) !important;
  padding: 0 !important;
  background: none !important;
  border: none !important;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: -0.01em;
}

.midnightLink:hover {
  color: var(--runn-blue) !important;
}

.midnightLink:focus-visible {
  text-decoration: underline;
}
