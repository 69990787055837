.dateInput {
  display: flex;
  flex-direction: column;
}

.dateInput label {
  font-size: 12px;
  color: var(--midnight);
  font-weight: 600;
}

.dateInput :global(.bp5-popover-target) {
  width: 100%;
}

.dateInput :global(.bp5-input-group input) {
  padding-right: 10px;
  height: 40px;
  box-shadow: none;
  border: 1px solid var(--smoke);
  font-size: 13px;
}

.dateInput :global(.bp5-input-group input)::placeholder {
  color: var(--shadow);
}

:global(.DayPicker-Day.DayPicker-Day--selected) {
  background-color: var(--runn-blue) !important;
}

.dateInput.error label {
  color: var(--alert) !important;
}

.dateInput.error input {
  border-color: var(--alert);
}

.dateInput.textAlignRight :global(.bp5-input-group input) {
  text-align: right;
  padding-right: 10px !important;
  padding-left: 0px !important;
}
