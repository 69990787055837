.overlay {
  background-color: transparent;
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.popover :global(.bp5-popover-arrow-fill) {
  fill: var(--snow);
}
