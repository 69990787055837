.wrapper {
  display: flex;
  flex-direction: column;
}

.wrapper textarea {
  border: 1px solid var(--smoke);
  border-radius: 2px;
  padding: 11px;
  margin: 0 0 11px 0;
  font-size: 13px;
  color: var(--midnight);
}

.wrapper textarea::placeholder {
  color: var(--shadow);
}

.wrapper textarea:focus-visible {
  border-color: var(--runn-blue);
}

.wrapper textarea:disabled {
  background: var(--ice);
  border: 1px solid var(--winter);
  color: rgba(92, 112, 128, 0.6);
  cursor: not-allowed;
  resize: none;
}
