.dropdownWrapper :global(.bp5-popover-content) {
  width: 50px;
}

.dropdownWrapper :global(.bp5-popover-content) {
  width: 50px;
}

.dropdownWrapper :global(.bp5-popover-content) button[disabled] {
  width: 50px;
}

.dropdown {
  font-size: 13px;
}

.dropdown :global(.bp5-menu-item-icon) {
  width: 18px;
}

.dropdown :global(.bp5-menu-item),
.dropdown :global(.bp5-switch) {
  padding: 9px 15px;
  color: var(--midnight);
  align-items: center;
  border-radius: 0;
}

.dropdown :global(.bp5-switch) {
  margin-bottom: 0;
  margin-right: 10px;
}

.dropdown:global(.bp5-menu) {
  min-width: 150px;
}

.dropdown
  :global(.bp5-control.bp5-switch input:checked .bp5-control-indicator) {
  background-color: var(--runn-blue) !important;
}

.dropdown :global(.bp5-menu-item.bp5-active) {
  background: rgb(228, 232, 250);
  color: rgb(60, 66, 96);
}

.dropdown :global(.bp5-menu-item.bp5-active):hover {
  background: rgba(167, 182, 194, 0.3);
  color: rgb(60, 66, 96);
}

.dropdown :global(.bp5-icon) {
  color: var(--midnight);
  margin: 0;
  margin-right: 3px;
}
.dropdown :global(.bp5-icon) svg {
  width: 14px;
}

.dropdown :global(.bp5-disabled .bp5-icon) {
  opacity: 0.6;
}

.dropdown :global(.bp5-menu-divider) {
  border-top: 1px solid var(--cloud);
  margin: 0;
}

.dropdown :global(.bp5-submenu-icon):after {
  margin-right: -8px;
}

.heading {
  color: var(--shadow);
  font-weight: 500;
  font-size: 11px;
  padding: 15px 15px 5px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  user-select: none;
  letter-spacing: 0.1em;
}

.divider {
  width: 100%;
  height: 1px;
  background: var(--cloud);
  margin: 10px 0;
}

.divider + .heading {
  padding-top: 5px;
}

:global(.bp5-submenu) > * > :global(.bp5-menu) {
  overflow: auto !important;
  max-height: 96vh;
}
