.children {
  padding: 1rem;
  padding-left: 0.75rem;
}

.outlineParent {
  box-shadow: inset 3px 0 1px -1px var(--winter);
  background-color: var(--snow);
}

.outline {
  position: relative;
  height: 100%;
  align-items: stretch;
  display: flex;
}
