.clientSelect {
  display: flex;
  align-items: center;
}

.clientSelect span {
  margin-left: 10px;
}

.newClient {
  border-top: 1px solid var(--cloud);
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: var(--runn-blue);
}
