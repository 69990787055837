.resetButton {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 2px;
}

.resetButton svg path {
  fill: var(--runn-blue);
}

.resetButton svg path:hover {
  fill: var(--runn-blue-hover);
}
