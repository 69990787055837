/* override blueprints global button */
:global(.bp5-button) {
  font-size: 12px;
  font-weight: 500;
  border-radius: 9999px;
  background-image: none;
  box-shadow: none !important;
  padding: 0 20px;
  min-height: 30px;
  border: 1px solid transparent;
  text-transform: none;
  letter-spacing: 0;
}

:global(.bp5-button.bp5-minimal.DayPicker-NavButton) {
  border-radius: 2px;
  padding: 0;
}

:global(.bp5-button .bp5-button-text) {
  white-space: nowrap;
}

:global(.bp5-button.bp5-small) {
  font-size: 12px;
  padding: 0 15px;
}

/* blueprint switch toggle inside button */
:global(.bp5-button .bp5-switch) {
  margin-bottom: 0;
  height: 100%;
}

:global(
    .bp5-control.bp5-switch input:checked:disabled ~ .bp5-control-indicator
  ) {
  background: rgba(64, 87, 223, 0.5);
}

:global(.bp5-button .bp5-switch.bp5-align-right) {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

:global(.bp5-button .bp5-control) {
  display: flex;
  align-items: center;
  margin: 0;
}

:global(.bp5-button .bp5-switch .bp5-control-indicator) {
  margin-top: 0;
}

:global(.bp5-button .bp5-switch.bp5-align-right .bp5-control-indicator) {
  position: absolute;
  right: 0;
  margin: 0;
}

/* We focus differently on solid vs outlined buttons */
:global(.bp5-button.bp5-intent-primary):not(:global(.bp5-outlined)):not(
    :global(.bp5-active)
  ):focus {
  box-shadow: 0 0 10px #516aff !important;
  border: 1px solid #fff;
}

:global(.bp5-button.bp5-outlined.bp5-intent-primary):focus {
  background-color: #fff;
  border-color: var(--runn-blue);
  color: var(--runn-blue);
}

:global(.bp5-button.bp5-intent-primary),
:global(.bp5-button.bp5-intent-primary:not(.bp5-outlined).bp5-active) {
  background-color: var(--runn-blue);
  border-color: var(--runn-blue);
  color: #fff;
}

:global(.bp5-button.bp5-intent-primary) svg path,
:global(.bp5-button.bp5-intent-primary.bp5-active) svg path,
:global(.bp5-button.bp5-outlined.bp5-intent-primary.bp5-active) svg path {
  fill: #fff;
}

:global(.bp5-button.bp5-intent-primary.bp5-disabled) {
  background: var(--runn-blue) !important;
  color: #fff;
  opacity: 0.5;
}

:global(.bp5-button.bp5-intent-primary.bp5-disabled)
  :global(.bp5-spinner-head) {
  stroke: var(--smoke) !important;
}

:global(.bp5-button.bp5-intent-primary.bp5-outlined) {
  background-color: #fff;
  border-color: var(--winter);
  color: var(--runn-blue);
}

:global(.bp5-button.bp5-intent-primary.bp5-outlined) svg path {
  fill: #516aff;
}

:global(.bp5-button.bp5-intent-primary.bp5-outlined.bp5-disabled) {
  background: #fff !important;
  color: var(--runn-blue);
  border-color: var(--winter);
  opacity: 0.5;
}

:global(.bp5-button.bp5-intent-primary.bp5-outlined.bp5-disabled) svg path {
  fill: #516aff !important;
}

:global(.bp5-button.bp5-intent-primary):hover,
:global(.bp5-button.bp5-intent-primary).hover {
  color: #fff;
  border-color: var(--runn-blue);
  background-color: var(--runn-blue-hover);
}

:global(.bp5-button.bp5-outlined.bp5-intent-primary:not(.bp5-disabled)):hover,
:global(.bp5-button.bp5-outlined.bp5-intent-primary:not(.bp5-disabled)).hover,
:global(.bp5-button.bp5-outlined.bp5-intent-primary.bp5-active) {
  color: var(--runn-blue);
  border-color: var(--runn-blue);
  background-color: #f0f2ff;
}

:global(.bp5-button.bp5-outlined.bp5-intent-primary):hover svg path,
:global(.bp5-button.bp5-outlined.bp5-intent-primary).hover svg path,
:global(.bp5-button.bp5-outlined.bp5-intent-primary.bp5-active) svg path {
  fill: #516aff;
}

.stretch:global(.bp5-button) {
  width: 100%;
}

.stretch :global(.bp5-button-text) {
  text-align: center;
  width: 100%;
}

:global(.bp5-button.bp5-intent-danger) {
  border-color: var(--alert);
}

:global(.bp5-button.bp5-intent-none) {
  border: 0px;
  background: none;
  color: var(--slate);
  font-weight: 600;
  padding: 0 8px;
}

:global(.bp5-button.bp5-intent-none):hover {
  background: none;
  color: var(--midnight);
}
