.container {
  height: 40px;
  display: flex;
  align-items: center;
}
.container:hover {
  background: var(--ice);
}

.label {
  flex: 1;
  padding: 0 12px;
  line-height: 40px;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
  font-size: 13px;
}

.checkbox {
  margin-right: 12px;
}
