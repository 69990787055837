.cellPopover {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
}

.calendarCell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  background-color: var(--white);
  position: relative;
  border-left: 1px solid var(--snow);
}

.today {
  background-color: var(--link-light) !important;
}

.today,
.today + .calendarCell {
  border-left: 1px solid #e0e4ff;
}

.todayAndTimeline {
  color: white;
  background-color: var(--slate);
  opacity: 1;
}

.calendarCell:not(.disabled):hover .plusIcon {
  display: flex;
}

.tooltip {
  display: flex !important;
  height: 100%;
  width: 100%;
  align-items: center;
}

.plusIcon {
  display: none;
  width: 100%;
  height: 100%;
  max-height: 25px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  padding: 1px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml, %3Csvg width='18px' height='18px' viewBox='0 0 18 18' version='1.1' xmlns='http://www.w3.org/2000/svg' %3E%3Ctitle%3EAdd%3C/title%3E%3Cpath fill='%234057de' d='M10,8 L10,5 C10,4.44771525 9.55228475,4 9,4 C8.44771525,4 8,4.44771525 8,5 L8,8 L5,8 C4.44771525,8 4,8.44771525 4,9 C4,9.55228475 4.44771525,10 5,10 L8,10 L8,13 C8,13.5522847 8.44771525,14 9,14 C9.55228475,14 10,13.5522847 10,13 L10,10 L13,10 C13.5522847,10 14,9.55228475 14,9 C14,8.44771525 13.5522847,8 13,8 L10,8 Z M9,18 C4.02943725,18 0,13.9705627 0,9 C0,4.02943725 4.02943725,0 9,0 C13.9705627,0 18,4.02943725 18,9 C18,13.9705627 13.9705627,18 9,18 Z'%3E%3C/path%3E%3C/svg%3E");
  cursor: pointer;
}

.circle {
  border-radius: 100%;
  background-color: white;
  width: 100%;
  height: 100%;
  max-width: 20px;
  max-height: 20px;
}

.timeOff {
  /* Hack it to grey, since we cant fill a background svg */
  filter: grayscale(0.8) brightness(1.3);
}

.disabled {
  cursor: not-allowed;
}

.nonWorkingDay {
  background-color: var(--ice);
  z-index: 3;
  border-left: 1px solid var(--cloud);
}

.holidayOverlapsTimeOff {
  /* 
    These colors are not in the design system. They were color picked
    to emulate timeOff curtain over a nonWorkingDay
  */
  background: #eeeef2;
  border-left: 1px solid #e3e5eb;
  z-index: 4;
  pointer-events: none;
}

.holidayDetails {
  display: flex;
  align-items: center;
}

.holidayDetails svg {
  margin-right: 5px;
}
