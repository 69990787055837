.wrapper {
  background-color: var(--snow);
  border-radius: 3px;
  display: flex;
  align-items: center;
  margin-top: 7.5px;
  margin-bottom: 7.5px;
}

.moreButton {
  display: flex;
  align-items: center;
  height: 100%;
}

.divider {
  height: 100%;
  width: 1px;
  background-color: var(--cloud);
}
