.skinnyPill {
  width: 100%;
  height: 30px;
  position: absolute;
  z-index: 9;
  border-radius: 3px;
  overflow: hidden; /* This is so that border-radius works on small width div */
  cursor: pointer;
}

.skinnyPillContainer {
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 4px;
}

.firstPill {
  height: 50%;
}

.tooltip {
  max-width: 200px;
}
