:root {
  --modal-header: 69px;
  --modal-footer: 69px;
}

.wrapper {
  width: 560px;
  color: var(--midnight);
  display: flex;
  overflow: auto;
}

.wrapperWide {
  width: 960px;
}

.wrapperWider {
  width: 1060px;
}

.wrapperAutoWidth {
  width: auto;
}

.panelLeft {
  background-color: var(--runn-midnight-blue);
  display: flex;
  flex-direction: column;
  max-height: var(--dialog-height);
  width: 100px;
  position: sticky;
  top: 0;
  align-items: center;
}

.tangram {
  position: absolute;
  bottom: 0;
  display: flex;
}

.tangram svg {
  height: 100%;
  width: 100%;
}

.panelRight {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.panelRight label {
  margin-bottom: 8.5px;
}

.header {
  font-size: 16px;
  font-weight: 500;
  height: var(--modal-header);
  padding: 30px 30px 15px 30px;
  color: var(--midnight);
  background-color: var(--ice);
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 15;
}

.headerWithCloseButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.closeIcon {
  margin-left: auto;
  cursor: pointer;
}

.closeIcon svg path {
  fill: var(--smoke);
}

.closeIcon:hover svg path {
  fill: var(--slate);
}

.header.bold {
  font-size: 24px;
  font-weight: 700;
}

.subText {
  font-weight: 400;
  margin-left: 12px;
  font-size: 14px;
}

.body {
  flex: 1;
  padding: 30px;
  width: 100%;
  max-width: 500px;
  overflow-y: auto;
}

.body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 15px;
}

.body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(0 0 0 / 4%);
  background: #fafafa;
}

.body::-webkit-scrollbar-thumb {
  background: #c2c2c2;
  border-radius: 40px;
  border: 4px solid #fafafa;
}

.body::-webkit-scrollbar-thumb:hover {
  background-color: #7d7e7d;
}

.bodyWide {
  max-width: none;
}

.footer {
  padding: 0 30px;
  background-color: var(--ice);
  height: var(--modal-footer);
  min-height: var(--modal-footer);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  width: 100%;
  bottom: 0;
  left: 0;
  grid-gap: var(--spacing-xs);
}

.footer button:not(:last-child) {
  margin-right: var(--spacing-xs) !important;
}
