.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 30px;
  border-radius: 30px;
  padding-left: 6px;

  background: #fff;
  border: 1px solid var(--winter);
}

.container.hideDropdown {
  padding-right: 6px;
}

.tentativeSwitch {
  padding-right: 7px;
  font-size: 12px;
  line-height: 16px;
}

.tentativeSwitch:global(.bp5-control.bp5-switch .bp5-control-indicator) {
  margin-right: 4px;
  width: 40px;
}

.switch {
  margin: 0;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.switch:global(.bp5-control:hover input:checked ~ .bp5-control-indicator),
.switch:global(.bp5-control input:checked ~ .bp5-control-indicator),
.switch:global(.bp5-control.bp5-switch input:checked ~ .bp5-control-indicator),
.switch:global(
    .bp5-control.bp5-switch
      input:checked:not(:disabled):active
      ~ .bp5-control-indicator
  ),
.switch:global(
    .bp5-control.bp5-switch:hover input:checked ~ .bp5-control-indicator
  ) {
  background: var(--sky-blue);
}

.popover {
  padding: 12px;
}

.projectSwitch {
  flex: 1;
  margin: 12px 0px;
}

.done {
  display: flex;
  justify-content: center;
}

.dropdownPopover {
  height: 100%;
}

.dropdown {
  border-radius: 30px;
  height: 100%;
}

.dropdown:global(.bp5-button.bp5-small) {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: 1px solid var(--winter);

  padding-left: 12px;
}

.doneButton {
  margin: 0;
  height: 40px;
  font-size: 12px;
  padding: 0 0px;
  border-radius: 0px;
  border-top: 1px solid var(--winter);
}

.doneButton:global(.bp5-button.bp5-minimal.bp5-intent-primary) {
  color: var(--runn-blue);
  background-color: #fff;
  border: none;
  border-top: 1px solid var(--winter);
}

.doneButton:hover:global(.bp5-button.bp5-minimal.bp5-intent-primary) {
  color: var(--runn-blue-hover);
  background-color: var(--ice);
}
