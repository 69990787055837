.projectRowSummary,
.templateRowSummary {
  background-color: #fff;
  border-bottom: 1px solid var(--cloud);
  transition: all ease-in-out 0.3s;
}

.templateRowSummary {
  background: #e6effb;
  border-bottom: 1px solid var(--template-border);
}

.alwaysShowProjectPhases {
  border-bottom: transparent;
}

.rowExpanded {
  transition: all ease-in-out 0.3s;
}

.tentativeLabel {
  font-size: 12px;
  font-weight: 500;
  color: var(--tentative);
}
