@media screen and (max-height: 700px) {
  .cardHeader {
    display: none;
  }
}
.sticky {
  position: sticky !important;
  bottom: 0px;
}

.budgetContainer {
  position: relative;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  color: var(--midnight);
}

.footerButtons {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background: var(--ice);
  border-top: 1px solid var(--cloud);
  grid-template-columns: 1fr;
  grid-column-gap: 10px;
  position: sticky;
  bottom: 0;
}

.deleteIconContainer {
  display: flex;
  cursor: pointer;
  align-items: center;
  position: absolute;
  right: -25px;
  opacity: 0;
  height: 100%;
  padding-left: 10px;
}

.deleteIconContainer.disabled {
  cursor: not-allowed;
}
.deleteIconContainer.disabled:hover svg path {
  opacity: 0.8;
  fill: var(--winter);
}

.deleteIconContainer:hover svg path {
  fill: var(--slate);
}

.tableRow:hover .deleteIconContainer {
  opacity: 1;
}

.tableRow {
  position: relative;
  display: inline-grid;
  grid-column-gap: 15px;
  width: 100%;
  min-height: 45px;
}

.tableRow input,
.tableRow :global(.bp5-input) {
  margin-bottom: 0;
}

.tableRow input:not([role="combobox"]),
.tableRow :global(.bp5-input):not([role="combobox"]) {
  text-align: right;
}

.tableRow a {
  margin-left: 12px;
  font-size: 13px;
}

.budgetContainer.hideFinancials .budgetEditorWrapper .tableRow {
  grid-template-columns: minmax(150px, 1fr) 120px;
}
.budgetContainer.hideFinancials .tableBody .tableRow {
  padding: 10px 0;
}

.tableRow:not(:only-child):last-child {
  border-bottom: none;
}

.tableColumn {
  display: grid;
  align-items: center;
}

.tableColumnRight {
  display: grid;
  align-items: center;
  grid-auto-rows: 45px;
  justify-content: right;
  text-align: right;
}

.tableColumn :global(.bp5-popover-wrapper) {
  justify-content: start;
}

.tableHeading {
  font-weight: 500;
  color: var(--slate);
  border-top: 1px solid var(--snow);
  border-bottom: 0;
  padding: 15px 30px 0 30px;
  min-height: 45px;
  grid-auto-rows: 45px;
}

.tableBody {
  padding: 0 30px;
}

.tableInfo {
  font-weight: 500;
  text-align: right;
  background: var(--white);
}

.tableInfo .tableRow {
  padding: 0 30px;
}

.footerMessage {
  color: var(--slate);
}
.footerMessage.over {
  color: var(--alert);
  font-weight: 600;
}
.footerMessage span {
  margin-left: 0.5rem;
}

.budgetTopSection {
  display: grid;
  grid-template-columns: 200px 110px 170px auto;
  top: 0;
  z-index: 1;
  background: var(--snow);
  padding: 20px 30px 20px 30px;
  grid-gap: 20px 10px;
}

.totalBudgetContainer {
  max-width: 180px;
  margin-left: auto;
  text-align: right;
}

.budgetLabel {
  color: var(--midnight);
  font-weight: 500;
  font-size: 13px;
  padding-bottom: 5px;
}

.invalidLabel {
  color: var(--alert);
}

.select {
  width: 200px;
  text-align: left;
}

/* BudgetTotals.tsx Specific */
.total {
  background: #fffcf5;
  font-size: 18px;
}

.total span {
  display: flex;
}

.budgetEditorWrapper .tableRow {
  border-top: 1px solid var(--cloud);
  grid-template-columns: minmax(150px, 1fr) fit-content(150px) 20px 120px 100px 120px;
}
.otherCostWrapper .tableRow {
  border-top: 1px solid var(--cloud);
  grid-template-columns: minmax(314px, 1fr) 95px 50px 80px 113px 113px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.otherCostWrapper .tableRow.tableHeading {
  padding-bottom: 0;
  padding-top: 15px;
}

.budgetContainer.hideFinancials .otherCostWrapper .tableRow {
  grid-template-columns: 500px 120px 130px;
}

.totalsWrapper > div:nth-child(odd) {
  background: var(--ice);
}

.tableInfo.tableRow {
  padding: 15px 30px;
  border-top: 1px solid var(--cloud);
}

.budgetTotalsRow .tableRow {
  grid-template-columns: 1fr auto 110px;
  font-size: 16px;
  font-weight: 600;
}
.expensesTotalsRow .tableRow {
  grid-template-columns: minmax(314px, 1fr) 95px 50px 80px 113px 113px;
}

.expensesHeader .tableRow {
  grid-template-columns: 1fr auto;
  background: var(--snow);
}
.tableWidget {
  padding: 0 30px;
  border-top: 1px solid var(--cloud);
  text-align: left;
}

.otherCostsTextInput {
  border: 1px solid var(--winter);
  padding: 0 10px !important;
  min-height: 30px !important;
}

.errorBorder {
  border-color: var(--alert);
}

.otherCostsDateInput :global(.bp5-input-group input) {
  height: 30px;
}

.unallocated span :global(.bp5-icon) {
  margin-left: 5px;
}

.client {
  position: absolute;
  left: 30px;
  display: flex;
  align-items: center;
  height: 69px;
  z-index: 20;
  max-width: 550px;
}

.client span {
  padding-left: 10px;
  font-size: 16px;
  font-weight: 600;
}

.transferRateArrow svg {
  fill: var(--winter) !important;
}

.transferRateArrow:hover svg {
  fill: var(--runn-blue) !important;
}

.otherExpensesWarning {
  color: var(--alert);
  font-weight: 500;
}

.otherExpensesLabel {
  gap: 6px;
}
