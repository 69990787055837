.container {
  position: sticky;
  top: 0px;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  row-gap: 10px;
  background: #fff;
  z-index: 1;
}

.container > div {
  display: flex;
  flex-direction: row;
  column-gap: var(--spacing-xxs);
  align-items: center;
}

.planSelect {
  max-width: 200px;
}
