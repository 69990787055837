.totalEffort {
  display: inline-flex;
}

.duration,
.daysEffort {
  padding: 0;
  width: 70px;
  border: 1px solid var(--smoke);
  border-radius: var(--rounded-sm);
  border-top-right-radius: var(--rounded-none);
  border-bottom-right-radius: var(--rounded-none);
  border-right: none !important;
}

.duration:focus-visible {
  border-color: var(--runn-blue);
  box-shadow: 1px 0 0 0 var(--runn-blue);
  position: relative;
  z-index: 1;
}

.noSelect {
  border-right: 1px solid var(--smoke) !important;
}
