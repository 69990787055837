.label {
  display: flex;
  align-items: center;
  background-color: var(--slate);
  padding: 3px 7px;
  margin: 2.5px;
  border-radius: 3px;
  color: var(--white);
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.01em;
}

.tooltip {
  display: flex;
}

.tooltip b {
  padding: 6px 5px 0 0;
}

.listWrapper {
  display: flex;
  max-width: 350px;
  align-items: center;
  flex-wrap: wrap;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 7px;
}
