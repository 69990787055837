.toolTip {
  display: flex;
  flex-direction: column;
  min-width: 275px;
  background: white;
  text-transform: capitalize;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.26);
  padding: 1rem;
}

.toolTip .date {
  text-align: center;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: none;
}

.toolTip th {
  font-weight: 500;
  padding-right: 18px;
}

.toolTip tbody td {
  font-size: 12px;
  color: var(--slate);
  line-height: 1.8;
}

.toolTip td:last-child {
  text-align: right;
}

.colorIcon {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 10px;
}

.colorIconSquare {
  border-radius: 0px;
}
