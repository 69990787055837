/* WARNING: fragile CSS selector that targets the FilterBlock Target */
.container:not(.disabled):hover ~ button,
.container:not(.disabled):hover ~ span > button,
.container.isOpen ~ button,
.container.isOpen ~ span > button {
  border-color: var(--runn-blue);
  color: var(--runn-blue);
}
.container:not(.disabled):hover ~ button svg path,
.container:not(.disabled):hover ~ span > button svg path,
.container.isOpen ~ button svg path,
.container.isOpen ~ span > button svg path {
  fill: var(--runn-blue);
}

.button {
  height: 30px;
  line-height: 30px;
  margin: 0 7px 0 15px;
  border: none;
  background-color: transparent !important;
  cursor: pointer;
  padding: 0;
  border-radius: 0;
  font-size: 12px;
  font-weight: 600;
  color: var(--shadow);
}
.button span {
  display: flex;
  align-items: center;
}
.button svg {
  position: relative;
  width: 18px;
}
.button svg path {
  fill: var(--shadow);
}

.button:disabled {
  color: var(--winter);
  cursor: default;
}
.button:disabled svg path {
  fill: var(--winter);
}

.button:not(:disabled):hover,
.container.isOpen .button {
  color: var(--runn-blue);
}
.button:not(:disabled):hover svg path,
.container.isOpen .button svg path {
  fill: var(--runn-blue);
}

.filterNameEditor {
  display: flex;
  align-items: center;
  height: 50px;
  width: 350px;
  border-radius: 4px;
}
.filterNameEditor .input {
  flex: 1;
  height: 100%;
  border: none;
}
.filterNameEditor .input::placeholder {
  color: var(--shadow);
}
.filterNameEditor .saveButton {
  height: 30px;
  width: 70px;
  margin-right: 10px;
  border-radius: 30px;
  background: var(--runn-blue);
  color: #fff;
  border: none;
  font-weight: 600;
  cursor: pointer;
}
.filterNameEditor .saveButton:not(:disabled):hover {
  background: var(--runn-blue-hover);
}
.filterNameEditor .saveButton:disabled {
  opacity: 0.5;
  cursor: default;
}
