.filterContainer {
  display: flex;
  justify-content: space-between;
  padding: 12px;
}

.checkboxTick {
  top: auto;
}

.tooltip {
  max-width: 500px;
}

.workstreamWarning {
  display: flex;
  gap: var(--spacing-xs);
  align-items: center;
  margin-right: auto;
  font-size: 13px;
  font-weight: 500;
  color: var(--slate);
}

.workstreamContainer {
  border-top: 1px solid var(--cloud);
  border-bottom: 1px solid var(--cloud);
  padding: 12px;
}

.select {
  width: 300px;
}
