.wrapper {
  margin-bottom: 8.5px;
  display: grid;
  grid-gap: 10px;
}

.tableRow {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1.5fr 1.5fr 20px;
}

.tableRow:not(.tableHeaderRow) {
  margin-bottom: 8.5px;
}

.tableRow Input {
  margin-bottom: 0;
}

.tableHeaderRow {
  font-size: 12px;
  color: var(--midnight);
  font-weight: 600;
}

.delete {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 40px;
}

.delete:not(.disabled):hover svg path {
  fill: var(--slate);
}

.disabled {
  cursor: not-allowed !important;
}

.footer {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.footer p {
  margin: 0;
}
