:root {
  --header-grid-gap: 10px;
  --controls-grid-gap: 7px;
}

.pageHeaderWrapper {
  background: #f3f4f5;
  width: 100%;
}

.pageHeaderWrapper.sticky {
  position: sticky;
  z-index: 5;
}

.pageHeader {
  padding: 24px 0 25px;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  grid-gap: var(--header-grid-gap);
  align-items: center;
}

.pageHeader:has(:global(.available-on-plans-chip)) {
  display: flex;
  justify-content: flex-start;
}

.metadata {
  flex-shrink: 0;
}

.metadata,
.controls {
  display: grid;
  grid-auto-flow: column;
  grid-gap: var(--header-grid-gap);
  align-items: center;
}

.controls {
  grid-gap: var(--controls-grid-gap);
}
.pageHeader:has(:global(.available-on-plans-chip)) .controls {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  width: 100%;
}

.title,
.title a {
  font-size: 16px;
  font-weight: 600;
  color: var(--midnight);
  display: flex;
}

.subtitle,
.subtitle a {
  font-size: 14px;
  margin-top: 5px;
  color: var(--midnight);
}

.icon {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.03);
  height: 40px;
  width: 40px;
}

.icon svg {
  height: 20px;
  width: 20px;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.headerLabels {
  display: inline-flex;
  margin: 5px 5px 15px 0;
}
