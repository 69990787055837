.container {
  border-radius: 3px;
  display: flex;
  align-items: center;
  background-color: var(--snow);
}

.name {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.01em;
  font-weight: 400;
  padding: 3px 7px;
}

.levelNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  min-width: 25px;
}

.moreButton {
  display: flex;
  align-items: center;
  height: 100%;
}

.iconWrapper {
  width: 28px;
  height: 25px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  height: 100%;
  width: 1px;
  background-color: var(--cloud);
}
