.roleBar {
  color: var(--slate);
  font-size: 11px;
  font-weight: 500;
  user-select: none;
  -webkit-user-select: none;
  background-color: var(--ice);
  border-bottom: 2px solid white;
  display: flex;
  justify-content: space-between;
}

.scheduledHours {
  cursor: "help";
  white-space: nowrap;
}
