/* override styles in <FilterBlock /> */

.targetSelected {
  display: flex;
}

.targetOptionLabel {
  flex: 1;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.targetOptionLevel {
  background-color: var(--snow);
  border-radius: 2px;
  padding: 0 4px;
  margin-left: 2px;
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
}

.tooltipItem {
  display: flex;

  & .targetOptionLevel {
    background-color: color-mix(in srgb, #636882, #000000 20%);
    background-color: #4f5368; /* fallback */
  }
}

/* styles for selecting a level */

.levelCheckboxContainer {
  height: 100%;
  margin-right: 8px;
}

.levelCheckbox {
  /* Hide the default checkbox appearance */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  /* Base styles */
  position: relative;
  width: 20px;
  height: 100%;
  margin: 0;
  backgroud: none;
  border: none;

  /* Remove default focus styles */
  outline: none;
}

/* The number label */
.levelCheckbox::after {
  z-index: 1;
  border: 1px solid var(--winter);
  background: var(--white);
  border-radius: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  content: attr(data-label);
  color: var(--shadow);
  font-size: 11px;

  position: absolute;
  height: 20px;
  top: 10px;
  left: 0;
  right: 0;

  transition: all 0.2s;
  cursor: pointer;
}

/* Checked state */
.levelCheckbox:checked::after {
  background: var(--link-light);
  border-color: var(--runn-blue);
  z-index: 2;
  color: var(--runn-blue);
}

/* Hover state */
.levelCheckbox:hover::after {
  z-index: 2;
  background-color: var(--snow);
  border-color: var(--shadow);
  color: var(--midnight);
}

.levelCheckbox:checked:hover::after {
  border-color: var(--runn-blue-hover);
  color: var(--runn-blue-hover);
}

/* Connected pill shape */
.levelCheckbox:first-of-type::after {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.levelCheckbox:last-of-type::after {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.levelCheckbox + .levelCheckbox {
  margin-left: -1px;
}
