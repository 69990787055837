.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.placeholder {
  background-color: var(--placeholder-background);
  border: 1px dashed var(--placeholder-border);
  display: inline-flex;
  border-radius: 100%;
}

.avatarInitials {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--cloud);
  color: var(--slate);
  font-weight: 500;
  font-size: 11px;
  border-radius: 100%;
  margin: 0;
  user-select: none;
  -webkit-user-select: none;
}

.blueInitials {
  background-color: rgba(104, 153, 241, 0.35);
  color: #fff;
}

.placeholderInitials {
  background-color: var(--placeholder-background);
  color: var(--sea-blue);
  font-weight: 700;
}

.avatar {
  border-radius: 100%;
}

.inactivePerson {
  opacity: 0.5;
}

.archivedIcon {
  background-color: var(--slate);
}

.name {
  font-weight: 600;
}

.subtitleText {
  font-weight: 400;
  margin-left: 6px;
}
