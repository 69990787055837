.emptyText {
  color: var(--slate);
}

.inputFieldsWrapper {
  padding-bottom: 20px;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
}

.tabContainer {
  padding-top: 35px;
}

.warning {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #fef9eb;
  border-radius: 2px;
  margin-bottom: 15px;
}

.message {
  padding-left: 10px;
  font-size: 12px;
  line-height: 18px;
}

.loadingWrapper {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: blink-animation 1s linear infinite;
  -webkit-animation: blink-animation 1s linear infinite;
}

.loadingWrapper p {
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
}

.tangramsWrapper svg {
  height: 45px;
  width: 55px;
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
