.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hasNoPeople {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--slate);
  margin: 40px 0 20px;
}
