.chartContainer {
  height: 100%;
  width: calc(
    100% - 1px
  ); /* Hack to fix graph overflowing screen and causing crazy wobblying */
  position: absolute;
  left: 0.05rem;
  z-index: 7;
}

.yAxis {
  height: 100%;
  width: 50px;
  position: absolute;
  left: -50px;
  background: var(--ice);
  color: var(--slate);
  font-size: 11px;
}
