.container {
  height: 40px;
  display: flex;
  justify-content: stretch;
  align-items: center;
}

.switch {
  flex: 1;
  margin: 0 12px;
}
