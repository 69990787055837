.wrapper.disabled {
  cursor: not-allowed;
}

.tooltipConflict {
  max-width: 485px;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.01em;
}

.actionButton {
  text-transform: capitalize;
}
