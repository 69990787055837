.popoverContainer {
  display: grid;
  max-height: 50vh;
  overflow-y: auto;
  scrollbar-color: var(--ice) #404854;
  scrollbar-width: thin;
  padding: var(--spacing-xs);
}

.popoverContainer::-webkit-scrollbar {
  -webkit-appearance: none;
}

.popoverContainer::-webkit-scrollbar:vertical {
  width: 7px;
  background-color: #404854;
}

.popoverContainer::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--ice);
}

.tooltipPopover :global(.bp5-popover-content) {
  padding: var(--spacing-xxs);
}

.fieldContainer {
  display: grid;
  grid-auto-flow: column;
  gap: var(--spacing-xxs);
  grid-template-columns: minmax(100px, 200px) minmax(100px, 1fr);
  margin-bottom: var(--spacing-small);
  max-width: 50vw;
}

.fieldContainer:last-child {
  margin-bottom: 0;
}

.selectTitle {
  padding: 4px 5px 0 0;
}

.selectTitle,
.title {
  overflow-wrap: break-word;
  font-weight: 600;
}

.itemContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.item {
  color: white;
  border-radius: 3px;
  background: #636882;
  padding: 3px 6px;
  margin: 2px;
  font-weight: 500;
}

.link {
  color: white !important; /* Override the blueprint link color */
}

.linkText {
  display: inline-block;
  margin-right: 6px;
}
