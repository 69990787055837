.wrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--cloud);
  padding: 25px;
  min-height: 60px;
  color: var(--midnight);
}

.wrapper.hasIcon {
  padding: 12px 25px 12px 15px;
}

.wrapper span {
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.closeIcon {
  margin-left: auto;
  cursor: pointer;
}

.closeIcon svg path {
  fill: var(--smoke);
}

.closeIcon:hover svg path {
  fill: var(--slate);
}

.icon {
  width: 40px;
  height: 40px;
  margin-right: 7px;
}

.icon > *,
.icon img {
  height: 40px !important;
  width: 40px !important;
}

.titleContainer {
  width: 400px;
}

.title {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.01em;
}

.subtitle {
  font-size: 14px;
  letter-spacing: -0.01em;
}

.workstreamLabel {
  color: var(--midnight);
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  left: 350px;
  top: 27px;
}
