:root {
  --side-panel: 400px;
  --side-panel-transition: 0.3s ease-in;
}

.sidePanel {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: var(--side-panel);
  right: 0;
  bottom: 0;
  z-index: 20;
  border-left: 1px solid var(--winter);
  background-color: #fff;
  transform: translateX(100%);
  transition: transform var(--side-panel-transition);
}

.sidePanel.isOpen {
  transform: translateX(0%);
}

.childrenWrapper {
  transition: var(--side-panel-transition);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.childrenWrapper.isOpen {
  padding-right: var(--side-panel);
}

.sidePanel :global(.react-select) {
  max-width: calc(var(--side-panel) - (var(--spacing-large) * 2));
}

.actionBtnContainer {
  position: absolute;
  display: flex;
  gap: var(--spacing-xxs);
  align-items: center;
  flex-direction: column;
  right: 400px;
  background-color: #fff;
  padding: var(--spacing-small) var(--spacing-default);
  width: fit-content;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  box-shadow:
    -1px 2px 4px rgba(0, 0, 0, 0.1),
    -2px 2px 3px rgba(0, 0, 0, 0.08);
}

.closeIconContainer {
  display: flex;
  justify-content: flex-end;
}

.closeButton {
  margin: var(--spacing-small) var(--spacing-default) 0 0;
}

.nameAndAvatar {
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-large) 0 var(--spacing-large);
}

.title {
  margin: 0 var(--spacing-xs);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.name {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  width: 280px;
  text-overflow: ellipsis;
  display: inline-block;
  text-align: start;
}

.subtitle {
  font-size: 13px;
}

.detailsTitle {
  font-size: 13px;
  font-weight: 700;
  margin: 0;
  padding-bottom: var(--spacing-xxs);
  color: var(--midnight);
}

.detailsValue {
  margin-bottom: var(--spacing-large);
  font-size: 13px;
  overflow-wrap: break-word;
}

.detailsValue > .bp5-button.bp5-intent-primary.bp5-outlined {
  min-height: auto;
}

.referencesContainer {
  display: flex;
  gap: var(--spacing-small);
  flex-wrap: wrap;
}

.reference {
  display: flex;
}

.referenceIcon {
  margin-right: var(--spacing-xxs);
}
