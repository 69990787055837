.container {
  display: flex;
  align-items: center;
  border-radius: var(--rounded);
  padding: var(--spacing-xs);
  line-height: 20px;
}

.info {
  background-color: rgba(104, 153, 241, 0.05);
}

.success {
  background-color: rgba(132, 219, 160, 0.05);
}

.warning {
  background-color: rgba(253, 205, 79, 0.05);
}
