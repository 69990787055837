.container {
  padding-bottom: 10px;
  max-height: 60vh;
  overflow-y: hidden;
  scrollbar-color: rgba(0, 0, 0, 0.5) var(--ice);
  scrollbar-width: thin;
}

.container:hover {
  overflow-y: auto;
}

.container::-webkit-scrollbar {
  -webkit-appearance: none;
}

.container::-webkit-scrollbar:vertical {
  width: 10px;
  background-color: var(--ice);
}

.container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(0 0 0 / 4%);
  background: #fafafa;
}

.container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Make sub menu line up with main menu */
.container :global(.bp5-transition-container) {
  margin-top: 5px;
}

.toggle {
  padding: 8px 0px 0px 15px;
}
