.popoverContent {
  background-color: #fff;
  min-width: 300px;
}

.popoverContent .body {
  padding: 15px;
}

.popoverContent input {
  margin-bottom: 0;
}

.popoverContent .footer {
  background-color: var(--snow);
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
}

.phasePopover {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.phasePopover :global(.bp5-popover-target) {
  width: 100%;
  height: 100%;
}

.footer section {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 10px;
}

.colorsContainer {
  height: 0;
  overflow: hidden;
  padding: 0 15px;
  transition: all ease-in 0.3s;
  background-color: var(--snow);
}

.colorsContainer.show {
  height: 30px;
  padding-top: 10px;
  overflow: none;
  transition: all ease-in 0.3s;
}

.colorIcon {
  width: 16px;
  height: 16px;
  border-radius: 100%;
}

.phaseDialog {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.phaseDialog .header {
  display: flex;
  justify-content: center;
  color: rgb(60, 66, 96);
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid rgb(232, 233, 240);
}

.phaseDialog .header input {
  margin-bottom: 0;
  padding: 14px 7px;
  font-size: 18px;
  text-align: center;
}

.phaseDialog .footer {
  width: 100%;
  border-top: 1px solid var(--cloud);
  background-color: var(--ice);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 40px;
}

.phaseDialog .statsWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 40px;
}
