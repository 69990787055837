.timelineEmpty {
  height: 45px;
  background-color: var(--snow);
  border-left: 1px solid var(--winter);
  box-shadow: inset 3px 3px 1px -1px var(--winter);
}

.timelineBlock {
  border-left: 1px solid var(--winter);
  padding-left: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

.timelineBlock:first-child {
  border-left: none !important;
}

.timelineContainer {
  position: relative;
  height: 45px;
  display: flex;
  width: 100%;
  color: var(--midnight);
  overflow: hidden;
  font-size: 11px;
  font-weight: 500;
  flex-direction: column;
  background: var(--snow);
  border-top: 1px solid var(--cloud);
  border-left: 1px solid var(--cloud);
}

.timelineHighlight {
  position: absolute;
  height: 3px;
  width: 100%;
  bottom: 0;
  z-index: 50;
  background-color: #828bdf;
}

.timelineRow {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  color: var(--slate);
}

.timelineDate {
  position: absolute;
  height: 22px;
  text-align: center;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
}

.startOfMonth {
  border-left: 1px solid var(--cloud);
}

.today {
  color: white !important;
  /* intentional custom color */
  background: #8e95bb !important;
  font-weight: 700;
  text-shadow: 1px 1px 2px var(--shadow);
}

.weekend {
  color: var(--slate);
  background: #fff;
}

.timelineDay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  position: relative;
}

.currentWeek {
  color: var(--midnight);
  background-color: #fff;
}

.currentMonth {
  color: var(--midnight);
}

.timelineWeek {
  display: flex;
  height: 100%;
}

.startOfWeekHalfYear {
  justify-content: left;
  padding-left: 2px;
}

.timelineWeeksWrapper {
  display: flex;
  height: 100%;
  width: 100%;
  background: var(--ice);
  border-top: 1px solid rgba(214, 216, 228, 0.4);
}

.timelineRowInner {
  flex: 1;
  display: flex;
  position: relative;
}

.weekNumber .date {
  position: absolute;
  left: 1px;
  margin: auto 0;
}
