.link {
  padding-left: var(--spacing-xxs);
}

.link path:hover {
  fill: var(--midnight);
}

.helpIcon {
  display: flex;
}
