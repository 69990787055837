.name {
  color: var(--midnight);
  display: flex;
  align-items: center;
}

.nameWithWorkstreams {
  font-weight: 600;
}

.personNameWrapper .name:hover {
  color: var(--runn-blue);
  cursor: pointer;
}

.personNameWrapper :global(.bp5-popover-wrapper) {
  justify-content: start;
}

.inactive {
  color: var(--slate);
  display: flex;
}

.inactiveWithWorkstreams {
  font-weight: 600;
}

.noAssignmentsToolTip {
  width: 260px;
  margin-left: 20px;
}

.readonly {
  pointer-events: none;
}

.subGroupInfo {
  margin-bottom: 0;
  font-size: 13px;
  line-height: 19px;
  color: var(--midnight);
  display: inline-block;
}

.subGroupInfoInactive {
  color: var(--slate);
}
