.popoverContainer {
  display: flex;
}

.tagTitle {
  font-weight: bolder;
  padding: 4px 5px 0 0;
}

.tagsContainer {
  display: flex;
  max-width: 350px;
  align-items: center;
  flex-wrap: wrap;
}

.tag {
  color: white;
  border-radius: 3px;
  background: #636882;
  padding: 3px 6px;
  margin: 2px;
  font-weight: 500;
}
