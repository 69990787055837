.references {
  margin-top: 25px;
}

.references summary {
  font-size: 12px;
  font-weight: 600;
}

.description {
  margin: 15px 0 25px 0;
  font-size: 13px;
}

.wrapper {
  margin-top: 0;
  display: grid;
  grid-gap: 10px;
  min-width: 200px;
  margin-bottom: 25px;
}

.tableRow {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 20px;
  grid-gap: 10px;
}

.tableRowHeader {
  font-weight: 500;
  color: var(--slate);
  font-size: 11px;
}

.delete {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.delete:focus {
  outline: none;
}

.delete:focus-visible svg path,
.delete:hover svg path {
  fill: var(--slate);
}
