.phasesWrapper {
  border-bottom: 1px solid var(--cloud);
  min-height: 35px;
}

.alwaysShowProjectPhases {
  border-bottom-width: 2px;
}

.phases {
  min-height: 30px;
  height: 100%;
  position: relative;
}

.phasesGrid {
  padding: 5px 0;
  display: grid;
  grid-auto-flow: dense;
  row-gap: 5px;
  position: relative;
  width: 100%;
  min-height: 100%;
  background-color: var(--ice);
}

.alwaysShowProjectPhases .phasesGrid {
  border-top: 1px solid var(--ice);
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  cursor: grabbing;
}

.title {
  font-weight: 500;
  font-size: 11px;
}

.divider {
  height: 100%;
  position: absolute;
  z-index: 1;
  width: 1px;
  background-color: var(--cloud);
}

.miniPill {
  height: 3px;
  border-radius: 3px;
  width: calc(100% - 2px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.miniPhasesGrid {
  padding: 5px 0;
  display: grid;
  grid-auto-flow: dense;
  row-gap: 3.5px;
  position: absolute;
  width: 100%;
  max-height: 50%;
  top: 50%;
  overflow: hidden;
}
