.projectOverview {
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
}

.disabled {
  pointer-events: none;
}
