.trialModal {
  display: flex;
  color: var(--midnight);
  max-width: 700px;
}

.title {
  font-size: 24px;
  font-weight: 600;
}

.info {
  padding: 50px 40px 20px 30px;
}

.leftSection {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  min-width: 340px;
  min-height: 320px;
}

.description {
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  padding: 15px 0 20px;
}

.callsToAction > * {
  margin-right: 10px;
}

.trialModal a {
  font-weight: 500;
}

.moreTime {
  padding: 0 30px var(--spacing-massive) 30px;
}

.accountDropdown {
  padding: 0 30px 30px 30px;
}

.deleteAccount {
  background-color: var(--ice);
  padding: 20px 30px 20px 30px;
}

.deleteAccount a {
  font-weight: 700;
  color: var(--midnight);
  text-decoration-line: underline;
}

.deleteAccount a:hover {
  color: var(--alert);
}
