.noteTitle summary {
  margin-top: var(--spacing-xs);
  font-size: 12px;
  font-weight: 600;
  color: var(--midnight);
}

.noteTitle textarea {
  height: 60px;
}

.noteTitle[open] summary {
  margin-bottom: var(--spacing-xs);
}
