.calendarMilestone {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 2px;
  transition: 400ms all;
  padding: 15px 18px;
  overflow: hidden;
  width: 300px;
}

.calendarMilestone :global(.bp5-divider) {
  margin: 5px 0;
}

.details {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 350px;
  position: relative;
  overflow: auto;
  scrollbar-width: thin;
}

.editFormContainer {
  width: 580px;
  height: 100%;
}

.nonBillableMilestone {
  background-color: var(--sea-blue-dark);
}

.tentativeMilestone {
  background-color: var(--sky-blue);
}

.itemContainer {
  padding: 8px 0;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  display: flex;
  font-size: 20px;
  font-weight: 500;
}

.title p {
  margin: 0 0 0 10px;
  font-size: 16px;
}

.date {
  font-weight: 600;
  margin-bottom: 10px;
}

.note {
  color: var(--shadow);
  margin: 5px 0 0 38px;
  line-height: 16px;
  overflow-x: hidden;
  white-space: pre-wrap;
}

.editButton {
  color: var(--shadow);
  cursor: pointer;
  align-self: start;
  margin-left: 4px;
  padding-right: 4px;
}

.editButton:hover {
  color: var(--midnight);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 10px;
  cursor: pointer;
}

.footer p {
  margin: 0 0 0 5px;
  font-weight: 600;
  color: var(--slate);
}

.footer:hover p {
  color: var(--midnight);
}

.footer:hover svg path {
  fill: var(--midnight);
}

.numberIcon {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--runn-blue);
  color: #fff;
  font-size: 13px;
  user-select: none;
}

.iconPopover,
.iconPopover > *,
.iconWrapper {
  /*
    Ensures that the milestone stays centered in the top half
    Regardless of project height
  */
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 50%;
}

.multipleMilestonesTooltip {
  padding: 0;
  margin: 0 0 0 15px;
}

.weekendsCollapsedIcon {
  width: 5px;
  height: 5px;
  background-color: var(--runn-blue);
  border-radius: var(--rounded-full);
  margin-top: 12px;
}
