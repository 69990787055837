.plannerRightColumn {
  min-width: 400px;
  flex-grow: 1;
  display: flex;
  position: relative;
  align-items: center;
}

.dropShadow {
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.highlight {
  position: absolute;
  z-index: 6;
  left: -4px;
  height: 80%;
  width: 3px;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  background-color: #828bdf;
}
