.inlineFlex {
  display: inline-flex;
}

.tag {
  height: 26px;
  display: inline-flex;
  padding: 0 10px;
  white-space: nowrap;
  position: relative;
  align-items: center;

  margin: 3px 2px;
  background: #ffffff;
  color: var(--midnight);
  border-radius: 4px;
  border: 1px solid var(--winter);
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
}

.tagContent {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.editable {
  cursor: pointer;
}

.editable.tag:hover {
  background: var(--cloud);
}

.light {
  background: var(--cloud);
}

.menuTarget {
  visibility: hidden;
  margin-right: -10px;
}

.tag:hover .menuTarget {
  visibility: visible;
}
