/*
  Blueprint doesn't allow us to easily update the submenu icon
  See: https://github.com/palantir/blueprint/issues/4196
  So this is a hack to use what we want
*/

.menuItem :global(.bp5-popover-target),
.menuItem :global(.bp5-text-overflow-ellipsis) {
  display: inline-flex;
  align-items: center;
}

.menuItem :global(.bp5-submenu-icon) {
  margin-top: 0;
}

.menuItem :global(.bp5-menu-item-icon) {
  width: 20px; /* To make sure all associated labels are vertically aligned.*/
}

.menuItem :global(.bp5-menu-item-icon) svg {
  width: 16px;
  height: 100%;
}

.menuItem:global(.bp5-disabled) :global(.bp5-menu-item-icon) svg > *,
.softDisabled :global(.bp5-menu-item-icon) svg > * {
  color: var(--shadow);
  fill: var(--shadow);
}

.softDisabled :global(.bp5-text-overflow-ellipsis) {
  color: var(--shadow);
}

.menuItem :global(.bp5-submenu-icon) svg {
  display: none !important;
}

.menuItem :global(.bp5-submenu-icon):after {
  margin-left: var(--spacing-small);
  visibility: visible;
  content: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 -1.25 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3 2C3 2.28 3.11 2.53 3.29 2.71L6.59 6L3.29 9.29C3.11 9.47 3 9.72 3 10C3 10.55 3.45 11 4 11C4.28 11 4.53 10.89 4.71 10.71L8.71 6.71C8.89 6.53 9 6.28 9 6C9 5.72 8.89 5.47 8.71 5.29L4.71 1.29C4.53 1.11 4.28 1 4 1C3.45 1 3 1.45 3 2Z' fill='%23707593'/%3E%3C/svg%3E%0A");
}
