.tableContainer {
  width: 100%;
  margin: 24px auto;
  border-collapse: collapse;
  overflow: auto;
}

.tableHeader {
  color: var(--midnight-grey);
  font-weight: 600;
  border-bottom: 1px solid var(--cloud);
}

.tableRow {
  border-bottom: 1px solid var(--cloud);
}

.tableContainer td,
.tableContainer th {
  padding: 12px 0;
  text-align: left;
}

.tableHeader th {
  min-width: 50px;
}

.tableHeader th:nth-child(5),
.tableRow td:nth-child(5) {
  text-align: center;
}

.tableHeader th,
.tableRow td {
  width: auto;
  white-space: nowrap;
  padding-right: 12px;
}

.tableHeader th:nth-child(6) {
  width: 200px;
}

.tableHeader th:nth-child(7) {
  width: 200px;
}

.tableRow td:nth-child(4) {
  color: var(--slate);
}

.uncheckedText {
  color: var(--shadow);
  font-size: 13px;
}
