.actionList {
  margin: 0;
  padding: 0;
  max-width: 200px;
}

.actionItem {
  list-style-type: none;
}

.actionItem button {
  width: 100%;
}

.actionItem + .actionItem {
  margin-top: 8px;
}

.tooltipLink {
  color: #f1d301;
}

.userInfoContainer {
  font-size: 12px;
  margin-top: 10px;
  margin-left: 5px;
}

.userInfoContainer p {
  margin: 0;
}

.userInfoContainer p span {
  font-weight: 500;
}
