.tooltip {
  margin-left: var(--spacing-xxs);
}

.url,
.url:visited {
  text-decoration: underline;
  color: white;
}

.inlineIcon {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 1px;
}
