.container {
  display: flex;
  align-items: center;
}

.container:hover .chevron path,
.container:hover .avatar {
  fill: #4057df;
  transition: all ease-in 0.3s;
  border: 2px solid #4057df;
}

.avatar {
  margin-right: 10px !important;
  transition: all ease-in 0.3s;
  border: 2px solid #d5d8e4;
}

.details {
  display: flex;
  flex-direction: column;
  padding-right: 0.5rem;
}

.account {
  display: flex;
  font-style: normal;
  font-size: 13px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: -0.13px;
}

.type {
  padding: 1px 5px 2px 5px;
  border-radius: 3px;
  margin-left: 4px;
  color: white;
  font-size: 0.8125rem;
}

.type.live {
  background: var(--shadow);
}

.type.test {
  background: var(--runn-blue);
}

.user {
  color: var(--slate);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.26px;
}

.chevron path {
  fill: #d5d8e4;
  transition: all ease-in 0.3s;
}
