.projectMilestonesWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 5;
}

div.tentativeMilestone {
  background-color: var(--sky-blue);
}

div.nonBillableMilestone {
  background-color: var(--sea-blue-dark);
}

.iconPopover,
.iconPopover > *,
.iconWrapper {
  /* 
    Ensures that the milestone stays centered in the top half
    Regardless of project height
  */
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 50%;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  cursor: grabbing;
}
