.container {
  position: relative;
}
.container.isResizing:after {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;
  cursor: ns-resize;
}

.inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 11px; /* add space for the resize handle */
  background: var(--snow);
}

.resizeHandle {
  z-index: 15; /* needs to be above the planner grid*/
  height: 11px;
  top: 0;
  cursor: ns-resize;
  position: absolute;
  left: 0;
  right: 0;

  background: var(--smoke);
  border-color: var(--shadow);
  border-top-style: solid;
  border-top-width: 1px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);

  display: flex;
  justify-content: space-between;
  padding: 0 1em;
}

.resizeHandleGrabIcon {
  width: 11px;
  height: 5px;
  margin-top: 2px;
  position: relative;
}
.resizeHandleGrabIcon:before,
.resizeHandleGrabIcon:after {
  content: "";
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: var(--midnight);
}
.resizeHandleGrabIcon:before {
  top: 0;
}
.resizeHandleGrabIcon:after {
  bottom: 0;
}

.container.isResizing .resizeHandle,
.resizeHandle:hover {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);
}

.isScrollable {
  overflow-y: auto;
  overflow-x: hidden;
}

.floatingCloseButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  position: absolute;
  top: -10px;
  right: var(--spacing-xs);
  z-index: 20;
  background-color: var(--white);
  border: 1px solid var(--smoke);
  border-radius: var(--rounded-full);
  cursor: pointer;
}

.floatingCloseButton svg path {
  fill: var(--runn-blue);
}

.floatingCloseButton:hover {
  border-color: var(--runn-blue);
  background-color: var(--link-light);
}
