.plannerGrid {
  display: grid;
  width: 100%;
  grid-template-columns: var(--planner-side-width) 4fr;
  background-color: #fff;
}

.gridRow100 {
  grid-column: 1 / -1;
}

.disabledCursor {
  cursor: not-allowed;
}

.disabled:not(.allowInteraction) {
  pointer-events: none;
}

.plannerGrid.disabled > * {
  background-color: var(--ice);
}

.plannerGrid.disabled > * > * {
  opacity: 0.5;
}

.plannerGrid.disabled :global(.bp5-control-indicator) {
  background-color: var(--smoke) !important;
}

.plannerGrid.disabled
  :global(.bp5-control):hover
  :global(.bp5-control-indicator) {
  background-color: var(--slate) !important;
}

.stickyBottom {
  position: sticky;
  bottom: -1px; /* smoother than 0 when split screen is open */
  z-index: 9;
}
