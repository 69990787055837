.placeholderSummaryRow {
  background-color: #fff;
  position: sticky;
  align-items: stretch;
  z-index: 10;
  border-bottom: 1px solid var(--cloud);
  transition: all ease-in-out 0.3s;
}

.rowExpanded {
  transition: all ease-in-out 0.3s;
}

.miniGroup {
  top: 30px;
}

/* In group is an additional mini group heading = 30px x 2 */
.miniGroup.inGroup {
  top: 60px;
}

.utilizationGroup {
  top: 55px;
}

/* Utilization group plus in group heading = 55px + 30px */
.utilizationGroup.inGroup {
  top: 85px;
}
