.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--slate);
  transform: rotate(90deg);
  cursor: pointer;
  width: 15px;
}

.icon:hover > svg,
.active > svg {
  fill: var(--midnight);
}

.active {
  color: var(--midnight);
}
