.tentative {
  color: var(--sky-blue-dark);
  width: fit-content;
  font-weight: 500;
  font-size: 13px;
  margin-top: 2px;
  margin-bottom: 0px;
}

.tentative > :global(.bp5-control-indicator) {
  margin-right: 5px;
}

.tentative:global(.bp5-control:hover input:checked ~ .bp5-control-indicator),
.tentative:global(.bp5-control input:checked ~ .bp5-control-indicator),
.tentative:global(
    .bp5-control.bp5-switch input:checked ~ .bp5-control-indicator
  ),
.tentative:global(
    .bp5-control.bp5-switch
      input:checked:not(:disabled):active
      ~ .bp5-control-indicator
  ),
.tentative:global(
    .bp5-control.bp5-switch:hover input:checked ~ .bp5-control-indicator
  ) {
  background: var(--sky-blue);
}
