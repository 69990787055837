.tabContainer {
  margin-top: 20px;
  display: grid;
  grid-gap: 15px;
}

.tabContainer > div {
  display: grid;
}

.tabContainer input {
  margin-bottom: 0px;
}

.contractWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 30px;
}

.contractWrapper :global(.bp5-popover-target) {
  width: 100%;
}

.inSidePanelContainer {
  padding: 0;
}

.inSidePanel {
  margin-top: 0;
  padding: var(--spacing-large);
}

.inSidePanel .twoColumnWrapper,
.inSidePanel.contractWrapper {
  grid-template-columns: 1fr;
  grid-gap: 15px;
}

.twoColumnWrapper {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 15px;
}

.tabContainer label {
  margin-bottom: var(--spacing-xs);
}

.tabContainer input {
  margin-bottom: 0;
}

.tabContainer label {
  margin-bottom: var(--spacing-xs);
}

.inviteLink {
  display: flex;
  align-items: center;
  color: var(--runn-blue);
}

.dateInput :global(.bp5-popover-target) {
  width: 100%;
}

.back {
  font-size: 13px;
  font-weight: 500;
  color: var(--slate);
  cursor: pointer;
  display: inline-block;
  display: flex;
  align-items: center;
  margin-right: var(--spacing-xs);
}

.back svg {
  width: 100%;
  height: 100%;
  width: 14px;
  height: 14px;
  fill: var(--shadow);
  transform: rotate(90deg);
}

.back:hover,
.back:hover svg {
  color: var(--midnight);
  fill: var(--midnight);
}

.holidayRemovalWarning {
  font-size: 11px;
  color: var(--slate);
  margin-top: var(--spacing-xxs);
  position: absolute;
}

.holidayRemovalWarning svg {
  width: 12px;
  height: 12px;
}

.warningWrapper > * {
  border-radius: 0 !important;
}

.warningWrapper > *:first-child {
  margin-top: var(--spacing-xxs);
}

.personTabs :global(.bp5-tab-list.bp5-large) {
  background-color: #fff !important;
  border-bottom: 1px solid var(--winter);
  padding: var(--spacing-default) 0 0 30px;
}

.personTabs :global(.bp5-tab) {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1em;
}

.personTabs :global(.bp5-tab[aria-selected="true"]) {
  color: var(--runn-blue);
}

.confirmContract {
  padding: 30px;
}
