.container {
  display: flex;
  background: #fff;
  border-bottom: 1px solid var(--cloud);
  padding: 12px 10px;
  position: sticky;
  top: 0;
  z-index: 15;
}

.container input {
  max-width: 100%;
}

.dropdownWrapper {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.container label {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 11px;
  font-weight: 500;
  margin-right: 5px;
  color: var(--midnight);
}
