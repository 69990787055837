.headingBar {
  display: flex;
  height: 30px;
  background-color: var(--ice);
  border-bottom: 1.5px solid var(--cloud) !important;
  color: var(--midnight);
  grid-column: 1 / -1;
  border-color: rgb(213, 216, 228);
  user-select: none;
  z-index: 15;
  padding-left: 12px;
}

.headingBar > * {
  font-size: 12px;
}

.text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  font-weight: 500;
  padding: 0.25rem 12px 0.25rem 0px;
  max-width: var(--planner-side-width);
}

.sticky {
  position: sticky;
  top: 0;
}

.collapsible {
  cursor: pointer;
}

.peopleGrouping {
  display: flex;
}

.count {
  font-size: 12px;
  padding-left: 10px;
}

.peopleHeadingCount {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.peopleHeadingCount :global(.bp5-popover-target) {
  display: flex;
}

.indented {
  padding-left: var(--spacing-medium);
  /*
    indented assumes it's a child of another HeadingBar so make
    sure it doesn't overlap on sticky scroll, via lower z-index
  */
  z-index: 14;
}
