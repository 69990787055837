.example {
  background: var(--cloud);
  padding: 8px;
  margin: 20px 30px 10px 30px;
  border-radius: 5px;
}

.table {
  padding: 0 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
}
