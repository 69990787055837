.red {
  color: var(--alert);
}

.body {
  margin-bottom: 20px;
}

.body p {
  line-height: 22px;
}
