.rightColumn {
  height: 100%;
  width: 100%;
  background: var(--ice);
}

.transparent {
  opacity: 0.5;
}

.weekends {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
}
