.panel {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-xl) var(--spacing-medium);
  gap: var(--spacing-medium);
}

.panel :global(.bp5-popover-target) {
  display: inline;
  align-items: center;
}

.section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
  padding: var(--spacing-medium);
  background-color: var(--ice);
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.label {
  margin: 0 0 11px 0;
}

.checkbox,
.switch,
.input {
  margin: 0;
}

.url,
.url:visited {
  text-decoration: underline;
  color: white;
}

.blueUrl,
.blueUrl:visited {
  text-decoration: underline;
}

.inlineIcon {
  display: inline-block;
  vertical-align: middle;
}

.attachedCallout {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.checkboxLabel {
  margin-left: var(--spacing-small);
}

.uploadSection {
  display: flex;
  gap: var(--spacing-medium);
  padding: var(--spacing-medium);
  background-color: var(--ice);
}

.dropZone {
  display: flex;
  border-radius: 6px;
  border: 1px dashed var(--slate);
  padding: var(--spacing-small) var(--spacing-xl);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 9px;
  align-items: center;
  color: var(--slate);
  line-height: 17px;
  font-size: 11px;
}

.uploadText {
  display: flex;
  gap: 7px;
}

.avatarContainer {
  position: relative;
}

.trashButton {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: var(--snow) !important;
  color: var(--midnight) !important;
  border: none !important;
  width: 18px !important;
  height: 18px !important;
  min-width: unset !important;
  min-height: unset !important;
  padding: 2px;
}

.warningIcon {
  width: 26px;
}
