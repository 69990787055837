.selectorListContainer {
  height: 315px;
  max-height: calc(90vh - 231px);
}

.noResults {
  height: 315px;
  max-height: calc(90vh - 231px);
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
}

.noItems {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noItems > *:first-child {
  margin-bottom: 10px;
}
