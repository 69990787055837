.label {
  margin-left: 10px;
}

a.link {
  color: #fff;
  text-decoration: underline;
}

.tooltipText {
  font-size: 14px;
  max-width: 200px;
  word-wrap: break-word;
}

.label,
a.link,
.tooltipText {
  text-transform: none;
  letter-spacing: initial;
}
