.backgroundBorderBottomOnly {
  min-height: 50px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='1' height='1'><rect fill='rgba(213,216,228,1)' x='0' y='0' width='1' height='1'/></svg>"),
    url("#");
  background-repeat: repeat-x, no-repeat;
  background-position:
    bottom right,
    0 0;
}

.shadow {
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.collapse {
  transition: height 500ms;
}

.collapse.disabled {
  opacity: 50%;
  pointer-events: none;
}
