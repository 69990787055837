.container {
  display: flex;
  justify-content: left;
  color: var(--midnight);
}

.container > *:first-child {
  margin-right: 30px;
}

.container label {
  display: block;
  margin-bottom: var(--spacing-xxs);
  letter-spacing: -0.01em;
  color: var(--midnight);
  font-weight: 600;
  font-size: 12px;
  letter-spacing: -0.01em;
}

.container input {
  text-align: center;
  color: var(--midnight) !important;
}

.numberAssignments {
  width: 45px;
}

.numberAssignmentsInput {
  height: 100%;
}

.dateInput {
  flex-direction: row;
}

.dateInput input {
  height: 30px !important;
}

.endingTypeContainer {
  display: flex;
  align-items: center;
  max-width: 150px;
  font-size: 13px;
}

.endingTypeContainer > *:first-child {
  min-width: 40px;
}

.endingTypeContainer:not(:last-child) {
  margin-bottom: var(--spacing-xs);
}
