/* All Pills */
.basicPill {
  position: absolute;
  height: 24px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px 0 3px;
  overflow: hidden;
  user-select: none;
  -webkit-user-select: none;
}

.wrappedPill {
  position: relative;
  z-index: 2;
}

.temporaryPill {
  z-index: 2;
}

.pillLabel {
  display: inline-flex;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;
  border-radius: 9px;
  height: 18px;
  font-size: 11px;
  font-weight: 600;
  padding: 0 6px;
  position: relative;
}

.pillContentContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.menu {
  display: none;
  transform: rotate(90deg);
  width: 18px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  right: 5px;
  margin-left: auto;
}

.selected .menu {
  display: flex;
}

.floating .menu {
  display: flex;
}

.menu:hover {
  border: 1px solid white;
}

/* floating (e.g being moved. multiselect. etc) */
.basicPill.floating {
  cursor: grabbing;
  z-index: 9;
  top: -6px;
  box-shadow: 0 5px 5px -1px rgba(0, 0, 0, 0.3);
}
.basicPill.floating.nonWorkingDay {
  z-index: 7;
  cursor: pointer;
}

.basicPill.highlight {
  cursor: pointer;
}

.basicPill.disabled {
  cursor: not-allowed !important;
}

.offscreenPillRight {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.offscreenPillLeft {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Assignments - No Phase */
.defaultAssignment {
  background: var(--no-phase);
  color: #fff;
}

/* Tentative Assignment */
.tentative {
  background: var(--tentative);
}

/* Conflict */
.conflict {
  background: #c8ccdf;
  border: 1px dashed black;
  z-index: 2;
}

.conflict .pillLabel {
  background: var(--midnight);
  color: white;
}

.conflict .menu {
  background-color: var(--midnight);
}

.conflict.selected {
  background: #868a9c;
  border: 1px dashed black;
}

.conflict .menu:hover {
  background: var(--midnight);
  border: 1px solid white;
}

.conflict.floating {
  background: #868a9c;
  border: 1px dashed black;
}

/* Time Offs */
.timeOff {
  background: var(--smoke);
  color: #fff;
  z-index: 8 !important;
}

.timeOff.externalTimeOff {
  z-index: 9 !important;
}
.timeOff.holidayTimeOff {
  z-index: 9 !important;
}
.timeOff.rosteredTimeOff {
  z-index: 10 !important;
}

.timeOff .pillLabel {
  background: var(--slate);
  color: white;
}

.timeOff:hover {
  background: var(--shadow);
}

.timeOff .menu {
  background-color: #5a5c6c;
}

/* Overbookings */
.overbooking {
  position: absolute;
  top: 0;
  z-index: 3;
  height: 4px;
  background: #e68a8e;
  border-bottom: 1px solid white;
}

/* Non working day */
.nonWorkingDay {
  z-index: 8;
}

.temporaryPill.nonWorkingDay {
  z-index: 9;
}

.highlight :global {
  animation: glow infinite 1s ease-in-out;
}
