.modalBody {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-default);
}

.assignmentUpdateWarning {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  margin-top: var(--spacing-small);

  color: var(--slate);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: -0.13px;
}
