:root {
  --dialog-height: calc(90vh - 50px);
}

.dialog {
  position: fixed;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0;
  border-radius: 3px;
  overflow: auto;
  transition: all ease-in 0.2s;
  top: 5vh;
  max-height: var(--dialog-height);
}

.portal > div {
  /* prevent dialog from displaying scrollbar on body */
  overflow: hidden !important;
}

.dialogBackdrop {
  background-color: rgba(0, 0, 0, 0.2);
}

.closeIcon {
  position: absolute;
  top: 25px;
  right: 30px;
  color: var(--shadow);
  cursor: pointer;
  z-index: 999;
}

.closeIcon:hover {
  color: var(--slate);
}
