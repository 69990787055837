.container {
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 5px;

  /* offset margin-left in FilterMenuButton & FilterBlock components */
  margin-left: -8px;
}

.container.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.resetButtonWrapper {
  margin-left: 10px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resetButtonWrapper span {
  display: flex;
  align-items: center;
}

.resetButton {
  background: none;
  border: none;
  cursor: pointer;
}

.resetButton svg {
  stroke-width: 0.3;
  stroke: var(--shadow);
  fill: var(--shadow);
  display: flex;
  align-items: center;
}

.resetButton:hover path {
  fill: var(--runn-blue-hover);
}

.filterName {
  margin-left: 8px;
  background: var(--runn-blue);
  border-radius: 28px;
  color: #fff;
  padding: 5px 15px;
}

.tentativeProjectsToggle {
  margin-left: 8px;
}
