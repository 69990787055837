label.inputLabel {
  font-size: 12px;
  color: var(--midnight);
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 8.5px;
}

.inputLabel-text {
  padding-right: 3px;
}

label.error {
  color: var(--alert) !important;
}
