.container {
  display: flex;
}

.toggleText {
  font-size: 12px;
  white-space: nowrap;
  font-weight: 500;
  cursor: pointer;
}

.toggleText:hover {
  color: var(--runn-blue);
}
