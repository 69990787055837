.phaseColorPicker {
  width: 100%;
  display: flex;
  grid-gap: 5px;
  justify-items: center;
}

.iconBox {
  width: 20px;
  height: 20px;
  border: 2px solid transparent;
  cursor: pointer;
}

.iconBox.active {
  border-color: var(--midnight);
}
