.inlineFlex {
  display: inline-flex;
}

.label {
  font-weight: 600;
  height: 26px;
  display: inline-flex;
}

.selectLabel {
  margin-bottom: var(--spacing-xs);
  align-items: center;
}

.item {
  height: 26px;
  display: inline-flex;
  padding: 0 var(--spacing-xs);
  white-space: nowrap;
  align-items: center;
  margin-right: var(--spacing-xs);
  margin-bottom: var(--spacing-xs);
  background: #ffffff;
  color: var(--midnight);
}

.itemContent {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.box {
  border-radius: 4px;
  border: 1px solid var(--winter);
  font-weight: 500;
  font-size: 12px;
}

a.box > * {
  text-decoration: none;
  color: var(--midnight);
}

a.box:hover {
  border: 1px solid var(--runn-blue);
  border-radius: 4px;
  background: var(--link-light);
}

.reference {
  padding-left: 0;
  padding-right: var(--spacing-small);
}

.tooltip {
  max-width: 800px;
}
