.groupHeader {
  height: 55px;
  position: sticky;
  top: 0;
  cursor: pointer;
  z-index: 14;
  display: grid;
  width: 100%;
  border-bottom: 1.5px solid var(--winter);
  grid-template-columns: var(--planner-side-width) 4fr;
  background: var(--ice);
}
