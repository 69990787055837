.container {
  position: relative;
}

.bg,
.card {
  left: 0;
  position: absolute;
  top: 0;
}

.bg {
  bottom: 0;
  background: #fff;
  opacity: 0.8;
  right: 0;
  z-index: 9;
}

.card {
  left: 50%;
  top: 50%;
  max-width: 600px;
  padding: 40px 60px 48px 60px;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.card:after {
  content: "";
  z-index: -2;
  background: linear-gradient(180deg, #4057df, #a200ff);
  margin: -10px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  filter: blur(10px);
  visibility: hidden;
  opacity: 0.5;
}

.card:hover:after {
  visibility: visible;
}

.benefitsHeading {
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0;
}

.benefitsDescription {
  margin-top: 10px;
  margin-bottom: 0;
}

.benefitsList {
  margin-top: 0;
  margin-bottom: 0;
}

.buttons {
  display: flex;
  column-gap: 10px;
}

.buttons > * {
  margin-top: 30px;
  max-width: fit-content;
}

.title {
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  margin-bottom: 20px;
}

.plans {
  font-size: 16px;
}

.contents {
  border-radius: 12px;
}
