.inputWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input {
  border: 1px solid var(--smoke);
  width: 100%;
  border-radius: var(--rounded-sm);
  padding: 11px;
  margin: 0 0 11px 0;
  font-size: 13px;
}

.input::placeholder {
  color: var(--shadow);
  opacity: 1;
}

.input:focus {
  border-color: var(--runn-blue);
}

.input:disabled {
  background: var(--ice) !important;
  border: 1px solid var(--smoke) !important;
  box-shadow: none;
  color: rgba(92, 112, 128, 0.6);
  cursor: not-allowed;
  resize: none;
}

.error input,
.error input:focus {
  border-color: var(--alert);
}

.nofocus .input:focus {
  border-color: var(--smoke);
}
