.viewButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--runn-blue);
  border-radius: 30px;
  height: 30px;
  max-width: 180px;
  color: #fff;
  margin-left: 9px;
}

.dropdownContainer:global(.bp5-menu) {
  padding: 0;
}

.dropdownListContainer {
  max-height: calc(50vh - (3 * 40px));
  overflow-y: auto;
}

.selectedViewName {
  padding: 0 10px 0 16px;
  cursor: pointer;
  font-weight: 600;
}

.searchInputContainer {
  display: flex;
  align-items: center;
  padding: 0 12px;
}
.searchInputContainer svg {
  width: 16px !important;
  height: 16px;
}
.searchInput {
  border: none;
  height: 40px;
  width: 100%;
  font-size: 13px;
}
.searchInput::placeholder {
  color: var(--shadow);
}

.defaultView p {
  margin: 0 0 2px 0;
}
.defaultView:global(.bp5-menu-item.bp5-active):hover {
  background: rgb(228, 232, 250);
  cursor: default;
}

.defaultViewButton {
  height: 20px;
  font-size: 12px;
  color: var(--runn-blue);
  font-weight: 600;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

.defaultViewButton:hover {
  color: var(--runn-blue-hover);
}

.noResultsText {
  color: var(--shadow);
  font-weight: 500;
  font-size: 11px;
  padding: 15px 15px 5px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.chevronButton:global(.bp5-button) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid var(--runn-blue-hover);
  padding: 0 18px 0 13px;
}
.chevronButton:global(.bp5-button svg) {
  fill: #fff;
}

.createButton {
  font-size: 13px;
  font-weight: 600;
  color: var(--runn-blue) !important;
}
.createButton:hover {
  background-color: var(--ice);
  color: var(--runn-blue-hover) !important;
}

.createButton > * {
  justify-content: center;
}

.clearButton {
  cursor: pointer;
  display: flex;
  align-items: center;
}
