.popoverContainer {
  max-height: 350px;
  overflow-y: auto;
  scrollbar-color: var(--ice) #404854;
  scrollbar-width: thin;
  scrollbar-gutter: stable;
  padding: var(--spacing-xs);
}

.popoverContainer::-webkit-scrollbar {
  -webkit-appearance: none;
}

.popoverContainer::-webkit-scrollbar:vertical {
  width: 7px;
  background-color: #404854;
}

.popoverContainer::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--ice);
}

.memberInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-xs);
  width: 100%;
}

.contentWrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.memberInfo span:last-child {
  margin-left: auto;
}

.memberCount {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xxs);
  letter-spacing: -1px;
  padding-right: 1px;
  white-space: nowrap;
}

.memberCount:hover path,
.memberCount:hover {
  color: var(--midnight);
  fill: var(--midnight);
}

.memberCount:hover circle {
  stroke: var(--midnight);
}

.tooltipHeading {
  font-size: 12px;
  font-weight: 510;
  line-height: 14px;
  letter-spacing: 1px;
  padding-bottom: var(--spacing-xs);
}

.tooltipText {
  font-size: 13px;
  line-height: 19px;
}

.groupNameTooltip {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tooltipPopover :global(.bp5-popover-content) {
  padding: var(--spacing-xxs);
}

.addPersonButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  padding: 0;
  cursor: pointer;
  border: none;
  border-radius: 100%;
  background-color: var(--shadow);
  height: 12px;
  width: 12px;
}

.addPersonButton:hover {
  background-color: var(--slate);
}
