.container {
  display: flex;
  position: relative;
  background: var(--runn-blue);
  border-radius: 30px;
  color: #fff;
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  font-weight: 600;
  margin-left: 6px;
  overflow: hidden;
  cursor: pointer;

  user-select: none;
  min-width: 80px;
}
.container:not(.disabled):hover {
  background: var(--runn-blue-hover);
}

.container.disabled {
  cursor: default;
  opacity: 0.5;
}

.container > span:last-child {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
}

.label {
  padding: 0 10px;
}

.bookmarkIcon {
  position: relative;
  width: 18px;
  top: 4px;
  margin-right: 4px;
}

.resetButton {
  color: var(--runn-blue);
  border: none;
  position: relative;
  z-index: 0;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;

  display: none;
  cursor: pointer;
}
.resetButton:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  z-index: -1;
  background: linear-gradient(90deg, #223ac900 0%, var(--runn-blue-hover) 50%);
  pointer-events: none;
}

.resetButton svg path {
  fill: #fff;
}
.container:not(.disabled):hover .resetButton {
  display: flex;
}
