.banner {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  min-height: 40px;
  bottom: 0px;
  z-index: 20;
  font-weight: 600;
  padding: var(--spacing-xs);

  background-color: var(--runn-midnight-blue);
  color: white;
}
