.container {
  display: flex;
  height: 4rem;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  font-size: 11px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: 500;
  position: sticky;
  background: #ffffff;
  border-bottom: 1px solid var(--snow);
  border-top-right-radius: 3px;
  border-top-left-radius: 2px;
  margin-top: -1px;
  z-index: 2;
}
