:global(#topBanner) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  color: rgba(255, 255, 255, 70);
  background-image: linear-gradient(
    180deg,
    var(--runn-blue),
    var(--runn-purple)
  );
  white-space: "pre-wrap";
}

:global(#topBanner a) {
  color: white;
  font-weight: bold;
  text-decoration: underline;
}

:global(#topBanner a:hover) {
  color: white;
  text-decoration: none;
}

.demoActions {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  position: absolute;
  right: 20px;
}

:global(#topBanner).superUserBanner {
  background-image: linear-gradient(180deg, #7f0e0e, #f74e4e);
}

.container {
  position: relative;
}

:global(#topBanner).testBanner {
  background-color: var(--warning);
  background-image: none;
  color: var(--midnight);
  a {
    color: var(--midnight);
  }
}
