.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 8px;
  cursor: default;
  max-width: 110px;
}

.container:hover {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.icon {
  width: 30px;
  height: 30px;
  margin-bottom: 8px;
}

.icon svg {
  width: 100%;
  height: 100%;
}

.name {
  font-family: monospace;
  font-weight: bold;
  font-size: 13px;
}
