.warning,
.icon {
  display: flex;
  align-items: center;
}

.warning > * {
  margin-right: var(--spacing-xs);
  color: var(--warning-light);
}
