.textContainer {
  font-weight: 500;
  color: var(--slate);
  font-size: 13px;
  opacity: 1 !important;
  text-align: center;
}

.buttonsContainer {
  display: flex;
  pointer-events: auto;
  opacity: 1 !important;
}

.icon {
  align-self: center;
}

.backButton,
.nextButton {
  display: flex;
  gap: var(--spacing-small);
}

.nextButton {
  margin-left: auto;
}
