.globalFilter {
  position: relative;
  margin-left: var(--spacing-small);
}

.globalFilter input {
  padding-left: 50px;
  padding-right: 35px;
  margin: 0;
}

.searchIcon {
  position: absolute;
  left: 12px;
  top: 12px;
  color: var(--shadow);
}

.clearIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  color: var(--shadow);
  cursor: pointer;
}

.clearIcon:hover {
  color: var(--midnight);
}
