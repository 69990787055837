.infoText {
  font-size: 12px;
  font-weight: 500;
  margin-top: 7px;
}

.title {
  display: flex;
  column-gap: var(--spacing-xs);
}

/* Reset left margin from feature plan chip */
.title > span:last-child > span {
  margin-left: initial;
}
