.container {
  display: flex;
  background: #fff;
  border-bottom: 1px solid var(--cloud);
  padding: 12px 10px;
}

.buttonGroup {
  height: 30px;
  margin-left: 10px;
}

.closeIcon:hover {
  color: var(--midnight);
}
