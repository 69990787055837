.table {
  width: 100%;
  text-align: left;
  position: relative;
}

.th {
  width: calc((100% - 5%) / 7);
  padding: 5px;
}

.td {
  height: 25px;
  padding: 15px 5px;
}

.tr:not(:last-child) {
  border-bottom: 1px solid var(--cloud);
}
