.tooltipEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
}

.text {
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.tooltipOverflow {
  overflow: hidden;
}
