.personSummaryGraph {
  display: flex;
  width: 100%;
  align-self: flex-end;
  background: #fff;
}

.capacitySection {
  height: 35px;
  margin-top: auto;
  position: relative;
}

.collapsedWeekend {
  position: absolute;
  z-index: 2;
  overflow: hidden;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}
