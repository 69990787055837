.container {
  overflow: hidden scroll;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.timeOffBarOpen {
  overflow: hidden;
}

.peoplePlannerList {
  display: flex;
  flex: 1;
  flex-direction: column;
}
