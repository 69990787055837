.multiValueLabelWrapper {
  display: flex;
}

.skillLabel {
  display: flex;
  align-items: center;
  padding: 3px 7px;
}

.competencyIconWrapper {
  display: flex;
  align-items: center;
  padding: 0px 5px;
}

.divider {
  width: 1px;
  height: 100%;
  background-color: var(--cloud);
}

.competencyTarget {
  display: flex;
  border-radius: 3px 0 0 3px;
}
