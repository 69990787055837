.container {
  display: flex;
  gap: var(--spacing-xxs);
  align-items: center;
}

.tooltipPortal {
  /* Select component has hardcoded 9999 z-index for
   * our wrapped react-select dropdown, we need this
   * here otherwise the tooltip will be hidden behind */
  z-index: 99999;
}

.tooltipPortal :global(.bp5-popover-content) {
  max-width: 600px;
}

.tooltipPortal :global(.bp5-popover-content) a {
  color: white;
  text-decoration: underline;
}

.tooltipPortal :global(.bp5-popover-content) a:hover {
  color: var(--snow);
}

.tooltip {
  display: flex !important;
}

.tooltip path:hover {
  fill: var(--midnight);
}
