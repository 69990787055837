.miniPicker,
.timeOffMiniPicker {
  padding: 15px;
  height: 100%;
  transition: all 250ms;
  min-width: 250px;
}

.miniPicker > *:not(:only-child):not(:last-child) {
  margin-bottom: var(--spacing-small);
}

.footer {
  display: flex;
  background-color: var(--snow);
  padding: 10px 15px;
  justify-content: space-between;
}

.footer > *:not(:last-child) {
  margin-right: 8px;
}

.title {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.effortContainer {
  flex-grow: 1;
}
