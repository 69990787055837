.dropdown {
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}

.item {
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.26px;
  width: 100%;
}

.item path {
  fill: var(--slate);
}

.item svg {
  width: 16px !important;
  height: 16px !important;
}

.currentAccount path {
  fill: var(--runn-blue);
}

.divider {
  color: var(--shadow) !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.divider :global(.bp5-heading) {
  font-size: 11px;
  margin-left: var(--spacing-xs);
}
