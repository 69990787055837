.wrapper {
  padding-bottom: 15px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
}

.reason {
  position: relative;
  margin-bottom: 10px;
}

.reason label {
  margin-bottom: 0;
  margin-right: 5px;
}
