.level {
  display: flex;
  align-items: center;
  padding: 8px 10px;
}

.level > *:last-child {
  width: 11px;
  margin-left: 7px;
  text-align: center;
  font-size: 13px;
}

.level:hover,
.dropdown .active {
  background-color: var(--snow);
  cursor: pointer;
}

.levelNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  min-width: 25px;
}

.dropdownTarget {
  display: flex;
  cursor: pointer;
}

.dropdownTarget:hover > *:first-child,
.dropdownTarget.active > *:first-child {
  background-color: var(--link-light);
  color: var(--runn-blue);
}

.dropdownTarget:hover svg rect,
.dropdownTarget.active svg rect {
  fill: rgba(var(--runn-blue_rgb), 0.3);
}

.dropdownTarget:hover :global(.competencyIconFill),
.dropdownTarget.active :global(.competencyIconFill) {
  fill: var(--runn-blue);
}
