.container {
  display: flex;
  color: var(--slate);
  font-size: 11px;
}

.buttonContainer {
  z-index: 1; /* Required because active button has z-index of 12 */
}

.toggleButton {
  padding: 0;
  min-height: 18px;
  min-width: 25px;
  font-weight: 600;
}

.lineContainer {
  display: flex;
  padding-right: 5px;
  flex-direction: column;
  color: var(--slate);
  font-size: 11px;
}

.budgetLine {
  background: var(--winter);
  border-radius: 3px;
  height: 6px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.budgetUsedLine {
  height: 100%;
  background: var(--runn-blue);
  border-radius: 3px;
}

.budgetOverLine {
  height: 8px;
  background: var(--alert);
  z-index: 2;
  position: absolute;
}

.textContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.text {
  padding-left: 5px;
  white-space: nowrap;
  color: var(--slate);
  font-size: 11px;
  font-weight: 500;
}

.overbudgetText {
  color: var(--alert);
}
