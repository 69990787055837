.datePicker :global(.DayPicker-Caption .bp5-divider) {
  display: none;
}
.datePicker :global(.DayPicker-Caption) {
  margin: 0 15px;
}

.datePicker :global(.DayPicker) {
  margin: 20px 20px 0 20px;
}

.datePicker :global(.bp5-datepicker) {
  padding: 0;
}

.datePicker :global(.DayPicker-Month) {
  margin: 0;
}

.datePicker :global(.DayPicker-Weekday) {
  font-weight: 400;
  padding: 20px 0 15px;
}

/* All days */
.datePicker :global(.DayPicker-Day) {
  border: 1px solid var(--cloud);
  border-radius: 0;
  height: 40px;
  width: 40px;
  color: var(--midnight);
}

/* Today */

.datePicker :global(.DayPicker-Day--today) {
  color: #fff !important;
  background-color: #8c67e6 !important;
  padding: 0;
  border: 1px solid #8c67e6;
}

/* Day when selected or hovered */
.datePicker :global(.DayPicker-Day--selected),
.datePicker :global(.DayPicker-Day--hovered-range-end),
.datePicker :global(.DayPicker-Day--hovered-range-start),
.datePicker :global(.DayPicker):focus {
  background-color: var(--runn-blue) !important;
  border-color: var(--runn-blue) !important;
  border-radius: 0 !important;
  color: white !important;
}

/* Days outside month */
.datePicker :global(.DayPicker-Day--outside) {
  border: none;
}

/* Disabled days */
.datePicker :global(.DayPicker-Day--disabled),
.datePicker :global(.DayPicker-Weekday abbr[title="Sat"]),
.datePicker :global(.DayPicker-Weekday abbr[title="Sun"]) {
  color: var(--winter);
  background: #fff;
}

.datePicker :global(.DayPicker-Day--disabled.DayPicker-Day--selected-range),
.datePicker :global(.DayPicker-Day--disabled.DayPicker-Day--hovered-range) {
  background-color: rgba(150, 163, 237, 0.4) !important;
  border-color: #b1baeb !important;
}

.datePicker
  :global(
    .DayPicker-Day--disabled.DayPicker-Day--selected-range.DayPicker-Day--today
  ),
.datePicker
  :global(
    .DayPicker-Day--disabled.DayPicker-Day--hovered-range.DayPicker-Day--today
  ) {
  background-color: #8c67e6 !important;
}

/* Selected range  */
.datePicker :global(.DayPicker-Day--selected-range),
.datePicker :global(.DayPicker-Day--hovered-range) {
  background-color: rgba(64, 87, 223, 0.55) !important;
  border-color: #8694e4 !important;
  color: #fff !important;
  border-radius: 0;
}

/* timeOff */
.datePicker :global(.DayPicker-Day--timeOff) {
  color: var(--slate);
  cursor: not-allowed;
  pointer-events: none;
  background: repeating-linear-gradient(
    45deg,
    rgba(213, 216, 228, 0.8),
    rgba(213, 216, 228, 0.8) 1px,
    transparent 1px,
    transparent 4px
  );
}
.datePicker :global(.DayPicker-Day--timeOff.DayPicker-Day--today) {
  color: #fff !important;
  background: repeating-linear-gradient(
    45deg,
    #a8a8a8,
    #a8a8a8 1px,
    #8c67e6 1px,
    #8c67e6 4px
  ) !important;
}
