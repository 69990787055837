.tooltipMessage {
  max-width: 200px;
}

.note {
  font-size: 12px;
}

.banner {
  padding: 8px 30px !important;
}
