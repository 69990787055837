.projectLineWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.projectLine {
  height: 3px;
  background-color: var(--runn-blue);
  position: relative;
  z-index: 1;
  border-radius: 3px;
}

.projectLine.nonBillable {
  background-color: var(--sea-blue-dark);
}

.projectLine.tentative {
  background-color: var(--tentative);
}

.disabled {
  background-color: var(--ice);
}
