.select {
  width: 200px;
  position: absolute !important;
  top: auto;
}

.select.roleSelector {
  right: 60px;
}

.select.workstreamSelector {
  right: 274px;
}

.itemLeft {
  max-width: 280px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  align-items: center;
}

.memberText {
  color: var(--shadow);
  position: absolute;
  right: 274px;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid var(--cloud);
}

.checkboxContainer {
  margin-left: auto;
  margin-right: 16px;
}

.checkboxContainer input[type="checkbox"]:disabled {
  border: 1px solid var(--smoke);
}
