.wrapper {
  line-height: 20px;
}

.checkboxLabel {
  margin-top: 6px;
  margin-left: var(--spacing-xs);
}

.checkboxLabel.disabled {
  color: var(--shadow);
  cursor: not-allowed;
}

.checkboxTick {
  top: auto;
}

.warning {
  display: flex;
  padding: var(--spacing-xs);
  background: #fef9eb;
  border-radius: 2px;
  margin-top: var(--spacing-xs);
}

.warning p {
  margin: 0;
}

.warning svg {
  margin-top: 5px;
}

.message {
  padding-left: var(--spacing-xs);
  font-size: 12px;
  font-weight: 600;
}
