.cellDivider {
  height: 100%;
  position: absolute;
  width: 1px;
  left: -1px;
  z-index: 0;
  background-color: var(--cloud);
}

.onTopOfPills {
  z-index: 7;
}

.bold {
  width: 2px !important;
  left: -2px !important;
}
