.container {
  background-color: #fff;
}

.rightColumn {
  display: flex;
  justify-content: space-evenly;
  background: var(--snow);
  border-color: var(--winter);
  border-left-style: solid;
  border-left-width: 1px;
  flex-grow: 1;
  min-width: 400px;
  box-shadow: inset 3px 0 1px -1px var(--winter);
}

.weekendLines {
  border-right: 1px solid var(--cloud);
}

.skeletonRow {
  display: flex;
  height: 55px;
  width: 100%;
  border-bottom: 1px solid var(--winter);
  align-items: center;
  justify-content: space-evenly;
}

.circle {
  width: 35px;
  height: 35px;
  background: rgb(232, 233, 240);
  border-radius: 100%;
}

.loadingSkeleton {
  border-color: rgb(232, 233, 240) !important;
  border-radius: 2px;
  box-shadow: none !important;
  background: rgb(232, 233, 240);
  background-clip: padding-box !important;
  cursor: default;
  color: transparent !important;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
  height: 25px;
  width: 75%;
}

.runnLogoError {
  height: 30px;
  width: 30px;
  margin-right: 23px;
  transform: rotate(180deg);
  filter: brightness(0);
}

.textLarge {
  font-size: 1.25rem;
  font-weight: 500;
}

.text {
  font-size: 0.875rem;
}

.textError {
  color: #ec0909;
}

.runnLogo {
  height: 30px;
  width: 30px;
  margin-right: 23px;
}

.messageBox {
  position: absolute;
  width: 20rem;
  box-shadow:
    0 2px 3px 0 rgba(0, 0, 0, 0.05),
    0 1px 0 0 #e1e4eb,
    0 1px 0 0 var(--winter);
  margin-top: 7rem;
  padding: 1rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  grid-gap: 10px;
}

.embed {
  margin: 0;
  position: relative;
}

.plannerHeaderSkeleton {
  grid-column: 1 / -1;
  position: relative;
  border-bottom: 1px solid var(--winter);
}

.pageControlsSkeleton {
  height: 43px;
  grid-column: 1 / -1;
}

.filterSkeleton {
  box-shadow: inset 0px 3px 1px -1px var(--winter);
  height: 45px;
}

.timelineSkeleton {
  border-left: 1px solid var(--winter);
  background: var(--snow);
  box-shadow: inset 3px 3px 1px -1px var(--winter);
}
