.phaseItem {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  min-height: 22px;
}

.phaseResizer {
  position: absolute;
  z-index: 2;
  height: 100%;
  top: 0;
  cursor: ew-resize;
}

.pill {
  display: flex;
  width: calc(100% - 2px);
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  user-select: none;
  cursor: pointer;
}

.hoverArea {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 4;
  cursor: copy;
}

.phaseItem :global(.bp5-popover-wrapper),
.phaseItem :global(.bp5-popover-target) {
  display: flex;
  justify-content: center;
  width: 100%;
}
