.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.body {
  background-color: white;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.log {
  white-space: nowrap;
  font-size: 16px;
}
