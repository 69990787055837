.search {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--winter);
  height: 40px;
}

.input :global(.bp5-input) {
  border: none;
  box-shadow: none;
}

/* Required as the Button adds global styles */
.closeButton:global(.bp5-button.bp5-intent-primary),
.closeButton:global(.bp5-button.bp5-intent-primary) {
  color: var(--runn-blue);
  background-color: white;
  border: none;
}
.closeButton:global(.bp5-button.bp5-intent-primary svg path) {
  fill: var(--runn-blue);
}

.hidden {
  visibility: hidden;
}
