.button {
  height: 30px;
  padding: 0 10px 0 8px;

  /* this margin is offset out by SuperSearch .container negative margin */
  margin-left: 8px;
}

.icon {
  position: relative;
  top: 3px;
  margin-right: 5px;
}

.label {
  font-weight: 600;
  line-height: 30px;
}
