.wrapper {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  font-size: 28px;
  border-radius: var(--rounded-full);
  border: 1px solid var(--smoke);
  background: var(--snow);
  z-index: 10;
}

.wrapper:focus,
.wrapper:hover {
  border-color: var(--runn-blue);
  outline: none;
}

.icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--rounded-full);
  border: none;
  border: none;
  width: 100%;
  height: 100%;
  min-width: 40px;
  min-height: 40px;
}

.remove {
  cursor: pointer;
  position: absolute;
  top: -15px;
  right: -8px;
  opacity: 0;
}

.wrapper:hover .remove {
  opacity: 1;
}

.remove svg path {
  fill: var(--runn-blue) !important;
}

.remove svg {
  background-color: #ffffff;
  border-radius: var(--rounded-full);
}
