.inputContainer {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.inputContainer div:first-of-type {
  flex-grow: 1;
}

.inputContainer input {
  width: 100%;
  border-radius: var(--spacing-tiny) 0 0 var(--spacing-tiny);
  border-right: none !important;
}

.inputContainer input:focus-visible {
  border-color: var(--runn-blue);
  box-shadow: 1px 0 0 0 var(--runn-blue);
  position: relative;
  z-index: 1;
}
