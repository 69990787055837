/* Add border around image and default initials */
.multiPersonAvatar {
  margin-left: -20px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 40px;
  height: 40px;
}

.multiPersonAvatar img,
.multiPersonAvatar p {
  border: 1px solid #fff;
}

.multiPersonAvatar:hover {
  z-index: 5;
  cursor: pointer;
  transform: translateY(-10px);
  transition: ease-in-out 0.1s;
}

.details {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  justify-content: center;
}

.details .title {
  font-weight: 500;
  font-size: 16px;
}

.avatarsContainer {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
}

.reverseAvatars {
  margin-left: 0;
  margin-right: -20px;
}
