.optionSelect {
  display: flex;
  align-items: center;
}

.newOption {
  border-top: 1px solid var(--cloud);
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 22px;
  height: 22px;
  border-radius: 100%;
  background-color: var(--runn-blue);
  margin-right: 10px;
}
