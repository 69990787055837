:root {
  --line-height: 20px;
}

.container {
  display: grid;
  grid-gap: 60px;
  grid-template-columns: 1fr 16px;
  border-bottom: 1px solid var(--snow);
  padding: 20px 0;
}

.readonly {
  grid-gap: 0px;
}

.noteWrapper {
  overflow: hidden;
  margin-bottom: 10px;
  max-height: calc(var(--line-height) * 3); /* 1 is the number of lines */
}

.details {
  font-size: 13px;
  color: var(--shadow);
  margin-top: 10px;
}

.container:last-child {
  border: none;
}

.showAll .noteWrapper {
  max-height: none;
}

.note {
  line-height: var(--line-height);
  /* pre-wrap is important to preserve the line breaks that come from textarea */
  white-space: pre-wrap;
}

.note a:hover {
  text-decoration: underline;
  color: var(--runn-blue);
}

.showMore {
  font-weight: 500;
  cursor: pointer;
}

.showMore:hover {
  color: var(--runn-blue);
}
