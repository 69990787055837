.wrapper {
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 15px;
  margin-bottom: 20px;
}

.tableRow {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 25px;
}

.tableHeaderRow {
  font-size: 12px;
  color: var(--midnight);
  font-weight: 600;
  border-bottom: 1px solid var(--cloud);
  padding-bottom: 15px;
}

.nameInput {
  margin: 0;
}

.dateInput label {
  margin: 0;
}

.delete {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.delete.disabled {
  cursor: not-allowed !important;
}

.delete:not(.disabled):hover svg path {
  fill: var(--slate);
}
