.textWrapper {
  min-height: 395px;
  width: 100%;
  color: var(--slate);
  font-size: 16px;
}

.textLink {
  color: var(--slate);
  margin-right: 15px;
  cursor: pointer;
  font-size: 12px;
}

.textLink:hover {
  color: var(--midnight);
}

.iframeWrapper {
  border: 0;
  display: flex;
  min-height: 455px;
  width: 100%;
}

.footerButtonsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-right: 15px;
}

.footerButtonsContainer > :global(.bp5-button) {
  min-width: 100px;
}

.footerButtonsContainer > b {
  padding: 0 13px 0 5px;
}
