/* ImportForm */

header.title {
  font-size: 15px;
  font-weight: 600;

  padding-bottom: 25px;
}

main.actionList {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

/* ActionItem */

a.actionItem {
  display: flex;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  width: 150px;
  height: 150px;
  flex-shrink: 0;

  border-radius: 8px;
  background: var(--white);

  box-shadow:
    0px 10px 20px 0px rgba(0, 0, 0, 0.1),
    0px 3px 6px 0px rgba(0, 0, 0, 0.2);
}

.icon {
  display: flex;
  width: 65px;
  height: 65px;
  justify-content: center;
  align-items: center;
}

.label {
  color: var(--midnight);
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}

/* ImportProjectViaIntegrationList */

.integrationImage {
  width: 65px;
  height: 65px;
}
