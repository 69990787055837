.removeButton {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  margin-left: 8px;
  cursor: pointer;
}

.removeButton:focus {
  outline: none;
}

.removeButton:focus-visible svg path,
.removeButton:hover svg path {
  fill: var(--slate);
}
