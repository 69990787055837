.highlightedArea {
  z-index: 1;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: var(--cloud);
  padding: 5px 0px;
  display: flex;
  align-items: flex-start;
}
