.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.holidaysLink {
  color: var(--runn-blue);
  font-size: 12px;
  white-space: nowrap;
  font-weight: 500;
}

.holidaysLink:hover {
  color: var(--midnight);
}

.empty {
  background-color: var(--ice);
  border-top: 1px solid var(--cloud);
}
