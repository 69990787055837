.wrapper {
  display: flex;
  align-items: center;
  margin-left: 3px;
  cursor: auto !important;
}

.wrapper > * {
  margin-left: 2px;
}

.wrapper svg {
  width: 13px;
}

.wrapper svg path {
  fill: var(--smoke);
}

.wrapper :global(.bp5-popover-target) {
  display: flex;
  align-items: center;
}
