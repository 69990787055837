.cardBody {
  padding: 30px;
}
.lastCopiedContainer {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background-color: #6899f10d;
  padding: 10px;
  margin-bottom: 20px;
}

.lastCopiedContainer svg path {
  fill: var(--sea-blue) !important;
}

.actionsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.actionsContainer button {
  margin-right: 0px;
  margin-left: 0px;
}
