.selectorModal {
  height: 100%;
  transition: ease-in 0.2s;
  color: var(--midnight);
  border-radius: 4px;
}

/* width */
.selectorModal ::-webkit-scrollbar {
  width: 15px;
}

/* Track */
.selectorModal ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(0 0 0 / 4%);
  background: #fafafa;
}

/* Handle */
.selectorModal ::-webkit-scrollbar-thumb {
  background: #c2c2c2;
  border-radius: 40px;
  border: 4px solid #fafafa;
}

.selectorModal ::-webkit-scrollbar-thumb:hover {
  background-color: #7d7e7d;
}
