.clientInfo,
.clientInfo :global(.bp5-popover-wrapper) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  --text-opacity: 100%;
}

.clientInfo.disabled {
  --text-opacity: 50%;
}

.title {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 1px;
}

.subtitle {
  color: var(--midnight);
  opacity: var(--text-opacity);
}

.clientInfo a {
  color: var(--midnight);
  opacity: var(--text-opacity);
}

.clientInfo.inactive a {
  color: var(--slate);
}

.clientInfo a:hover {
  color: var(--runn-blue-hover);
}

.temp,
.temp a {
  cursor: help;
  color: var(--slate);
}

.fakeLink:hover {
  cursor: pointer;
  color: var(--runn-blue-hover);
}

.reverse {
  text-align: right;
  margin-right: 10px;
}

.logo {
  justify-self: center;
}

.tentative {
  color: var(--sky-blue-dark);
  font-weight: 500;
  font-size: 13px;
}

.inactive {
  color: var(--slate);
}

.icon {
  justify-self: center;
}

.icon.inactive {
  opacity: 0.5;
}
.inactive .subtitle {
  color: var(--slate);
}

.isMember {
  color: var(--slate);
  font-size: 13px;
}
