.name {
  font-weight: 600;
}

.row {
  display: grid;
  grid-template-columns: 1fr 4fr;
  padding: var(--spacing-xs) 0;
  grid-gap: var(--spacing-medium);
}
