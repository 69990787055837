.selectorSearchContainer {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 30px 0 22px;
  background-color: #fff;
  border-bottom: 1px solid var(--cloud);
  position: relative;
  min-height: 60px;
}

.selectorSearchInput {
  min-height: 48px;
  border: none;
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  margin-left: 5px;
}

.selectorSearchInput::placeholder {
  color: var(--smoke);
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
}

.favourites {
  color: var(--smoke);
  cursor: pointer;
}

.favourites.active {
  color: var(--runn-blue);
}

.iconsContainer {
  display: flex;
}

.iconsContainer > * {
  margin-left: 10px;
}
