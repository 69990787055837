.personDetails,
.personDetails :global(.bp5-popover-wrapper) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
}

.title {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 1px;
}

.personDetails a {
  color: var(--midnight);
}

.personDetails a:hover {
  color: var(--runn-blue);
}

.subtitle {
  font-size: 13px;
  font-weight: 400;
  color: var(--midnight);
}
