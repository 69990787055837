.select {
  margin-top: 20px;
}

.viewLink {
  margin-bottom: 0;
  margin-top: 20px;
  font-weight: bold;
}

.gradientBorderWrapper .viewLink {
  margin-top: 0;
}
