.plannerLeftColumn {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: minmax(40px, auto) 1fr;
  grid-gap: 8px;
  user-select: none;
  padding-top: 12.5px;
  padding-bottom: 12.5px;
  padding-left: 12px;
  padding-right: 12px;
  color: var(--midnight);
}

.callToAction {
  display: flex;
  background-color: var(--ice);
  border-top: 1px solid var(--cloud);
}

.plannerLeftColumn :global(.bp5-icon) {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--smoke);
  cursor: pointer;
}

.plannerLeftColumn :global(.bp5-icon):hover > svg {
  fill: var(--midnight);
}

.plannerLeftColumn :global(.row-icons) {
  opacity: 1;
  transition: opacity ease-in 0.3s;
}

.plannerLeftColumn:hover :global(.row-icons),
.menuIsOpen :global(.row-icons) {
  opacity: 1;
  transition: opacity ease-in 0.3s;
}

.plannerLeftColumn:hover :global(.row-icons.bp5-icon-chevron-down) > svg,
.plannerLeftColumn:hover :global(.row-icons.bp5-icon-chevron-up) > svg {
  fill: var(--midnight);
}

.plannerLeftColumn :global(.show-icon) {
  opacity: 1;
}

:global(.paddedGroup) .plannerLeftColumn {
  padding-left: 38px;
}

.menuIsOpen .moreIcon svg {
  fill: var(--midnight);
}

.moreIcon {
  transform: rotate(90deg);
}

.disableLinks a {
  pointer-events: none;
}
