.wrapper {
  border-radius: var(--rounded-full);
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  overflow: hidden;
  background: var(--snow);
}

.defaultClient {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  height: 100%;
}

.defaultClient svg {
  height: 50%;
}

.emoji {
  line-height: 1px;
}

.archivedIcon {
  background: var(--slate);
}
