.title {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.14px;
  padding-bottom: var(--spacing-medium);
}

.description {
  font-size: 12px;
  font-weight: 500;
  margin-top: 7px;
}

.footer {
  justify-content: space-between;
}

.footer a {
  display: flex;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.12px;
  line-height: 12px;
}

.footer a > *:first-child {
  margin-right: var(--spacing-xxs);
}
