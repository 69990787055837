.infoContainer {
  display: flex;
  align-items: center;
  color: var(--slate);
  font-size: 13px;
  margin-right: auto;
}

.infoContainer p {
  margin: 0 0 0 5px;
}

.viewName {
  margin: 0 5px;
  font-weight: 600;
  color: var(--midnight);
  border-bottom: 1.5px solid var(--midnight);
}

.tooltip {
  max-width: 80vw;
  max-height: 80vh;
}

.minimal {
  width: 100%;
}

.minimal > * {
  display: inline !important; /* important to override tooltip default */
}
