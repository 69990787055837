/* Note: the li's within ul.navigation use .nav-item & .nav-label in runn-parts.css  */
ul.navigation {
  position: fixed;
  z-index: 17;
  display: flex;
  background-color: #fff;
  height: 51px;
  left: 50px;
  padding: 2px 0 0 0;
  margin: 0;
  list-style-type: none;
}

ul.navDropdown {
  position: absolute;
  min-width: 160px;
  background: #fff;
  top: 50px;
  border-radius: 2px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 5px 0;
  margin: 0;
  list-style-type: none;
}

ul.navDropdown li {
  padding: 4px 13px 4px 0;
}

ul.navDropdown a {
  cursor: pointer;
  color: var(--midnight);
  font-size: 13px;
}

ul.navDropdown li:hover,
ul.navDropdown a:hover {
  background-color: var(--snow);
}

ul.navDropdown :global(.bp5-menu-item) {
  padding-left: 10px;
}

.menuItemNoIcon {
  margin-left: 5px;
}

.divider {
  width: 100%;
  height: 1px;
  background: var(--cloud);
  margin: 5px 0;
}

.heading,
.betaContainer {
  padding: 12px 20px 5px 15px !important;
}

.heading {
  background: none !important;
  color: var(--shadow) !important;
  font-size: 11px !important;
  cursor: default !important;
  font-weight: 500 !important;
  letter-spacing: 0.1em !important;
}

.bannerLayout[data-banner-active="true"] {
  top: 40px;
}

.bannerLayout[data-banner-active="false"] {
  top: 0px;
}

.betaContainer:hover {
  background-color: transparent !important;
}

.betaContainer p {
  margin-top: 5px;
}

.betaTitle {
  color: var(--midnight);
  font-size: 16zpx;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.18px;
  margin-bottom: 0;
}

.betaText {
  font-size: 12px;
}
