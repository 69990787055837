.buttons {
  display: flex;
  column-gap: 10px;
}

.button {
  display: flex;
  padding: 7px 24px !important;
  border-radius: 3px;
  border: var(--runn-purple) 1px solid !important;
  background: var(--runn-purple-gradient) !important;
  background-clip: text !important;
  color: transparent !important;
}

.rounded {
  border-radius: 30px;
}
.solid {
  background: linear-gradient(179deg, #4057df 0.43%, #a200ff 99.45%) !important;
  color: white !important;
}
.solid:hover {
  background: var(--runn-purple-gradient);
}

.outline {
  background: white;
}
