.header {
  background: var(--ice);
  border-radius: 3px 3px 0px 0px;
  padding: 15px 20px;
  display: grid;
  grid-template-columns: 280px 1fr;
  border: 1px solid var(--winter);
  border-bottom: none;
}

.headerColumn {
  display: flex;
  align-items: center;
  color: var(--midnight);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
}

.noResults {
  background: white;
  border: 1px solid var(--winter);
  border-top: 1px solid var(--cloud);
  padding: 25px 0;
  color: var(--smoke);
  text-align: center;
}

.row {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  border-bottom: 1px solid var(--cloud);
  padding: 5px 15px;
  min-height: 65px;
  align-items: center;
  color: var(--slate);
  font-weight: 400;
}

.row .nameCell {
  color: var(--midnight);
  font-weight: 600;
}

.row:not(.headerRow):hover {
  background-color: var(--ice);
}

.headerRow {
  padding: 30px 15px 10px 15px;
  background-color: white;
  border-top: 1px solid var(--cloud);
  font-weight: 500;
}

.sortIcons {
  padding-left: 5px;
}

.statusFilter {
  display: flex;
  align-items: center;
  justify-self: flex-end;
  z-index: 2;
}

.statusFilter span {
  color: #969ab3;
  font-weight: 500;
  padding-right: 15px;
}

.stickyWrapper {
  position: sticky;
  z-index: 1;
}

.table {
  background-color: #fff;
  border: 1px solid var(--winter);
  border-top: 0;
}

.buttons {
  display: flex;
  align-items: center;
  justify-self: flex-end;
}
