.dropdown :global(.bp5-menu-item) {
  padding: 0;
}

.menuItemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.menuItemName {
  padding: 9px 5px 9px 15px;
  width: 100%;
}

.menuItem:global(.bp5-menu-item) :global(.bp5-icon > svg) {
  fill: var(--slate);
}
.menuItem:global(.bp5-menu-item:hover) :global(.bp5-icon > svg) {
  fill: var(--slate);
}

.iconsContainer {
  display: inline-block;
}

.iconsContainer > * {
  vertical-align: middle;
}

.refreshIcon {
  margin: 0 10px 0 8px;
}

.icon svg,
.icon path {
  fill: var(--slate);
}
.icon:hover > svg path {
  fill: var(--midnight) !important;
}
.icon:hover > svg {
  fill: var(--midnight) !important;
}
