.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: 1.1em;
  border-bottom: 1px solid var(--cloud);
  color: var(--midnight);
  width: 100%;
}

.header section {
  display: flex;
  flex: 1 auto;
  width: 100%;
}

.leftSide {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  margin-left: auto;
}

.rightSide {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  margin-right: auto;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 60px;
}

.clock {
  display: flex;
}

.details {
  display: flex;
  align-items: center;
}

.details .title {
  font-weight: 500;
  font-size: 16px;
}
