.button:global(.bp5-button.bp5-intent-primary.bp5-outlined) {
  background: none !important;
  padding: 7px !important;
  border: none !important;
  cursor: pointer;
  font-weight: 500;
  min-width: auto;
}

.button:global(.bp5-button.bp5-intent-primary.bp5-outlined) svg path,
.button:global(.bp5-button.bp5-intent-primary.bp5-outlined) > svg rect {
  fill: var(--slate) !important;
}

.button:global(.bp5-button.bp5-intent-primary.bp5-outlined).active svg path,
.button:global(.bp5-button.bp5-intent-primary.bp5-outlined):hover svg path {
  fill: var(--midnight) !important;
}

.button:global(.bp5-button.bp5-intent-primary.bp5-outlined.bp5-disabled) {
  cursor: not-allowed;
}

.button:global(.bp5-button.bp5-intent-primary.bp5-outlined.bp5-disabled)
  svg
  path,
.button:global(.bp5-button.bp5-intent-primary.bp5-outlined.bp5-disabled)
  > svg
  rect {
  fill: var(--winter) !important;
}

.button :global(.bp5-button-text) {
  display: flex;
  align-items: center;
}
