.container {
  display: inline-block;
  overflow: hidden;
}

.groupHeaderText {
  display: flex;
  gap: 4px;
  align-items: center;
}

.groupHeaderText :global(.bp5-icon) > svg,
.groupHeaderText :global(.bp5-icon:hover) > svg {
  cursor: default;
  fill: var(--slate);
  width: 11px;
  height: 11px;
}

.groupHeaderText:hover > path {
  fill: var(--midnight);
}

.groupHeaderTooltipText {
  color: white;
  border-radius: 3px;
  background: #636882;
  padding: 3px 6px;
  margin: 2px;
  font-weight: 500;
}
