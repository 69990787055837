.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-weight: bold;
  display: flex;
  gap: var(--spacing-xxs);
  align-items: center;
}

.tooltip path:hover {
  fill: var(--midnight);
}

.seperator {
  border: none;
  border-bottom: 1px solid var(--smoke);
  width: 2rem;
}

.value {
  color: #67d0d5;
  font-size: 1.5rem;
  font-weight: bold;
}

.warning {
  color: var(--alert);
}
