.wrapper :global(.bp5-label) {
  font-size: 12px;
  color: var(--midnight);
  font-weight: 600;
}

.wrapper :global(.bp5-control) {
  font-size: 13px;
}

.duration {
  max-width: 90px;
  font-size: 14px !important;
}

.partTime {
  margin-left: 26px;
  font-size: 13px;
}

.rosteredDays {
  display: grid;
  margin: 10px 0;
  grid-template-columns: repeat(auto-fit, minmax(auto, 26px));
  grid-gap: 10px;
}

.rosteredDay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--runn-blue);
  height: 26px;
  width: 26px;
  border-radius: 100%;
  color: white;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
}

.rosteredDay:hover {
  background-color: var(--runn-midnight-blue);
}

.rosteredDay.deselected {
  background-color: var(--cloud);
  color: #969ab3;
}

.rosteredDay.deselected:hover {
  background-color: #969ab3;
  color: var(--cloud);
}

.rosteredDay.disabled {
  pointer-events: none;
  cursor: not-allowed;
}
