.collapse {
  transition: height 500ms;
  grid-column: 1 / -1;
}

.collapse.disabled {
  opacity: 50%;
}

.disabled:not(.allowInteraction) {
  pointer-events: none;
}
