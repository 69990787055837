.trialImageContainer {
  display: flex;
  width: 100%;
  max-width: 250px;
  background: linear-gradient(180deg, #21bfda 0%, #3f61dc 100%);
}

.trialImageContainer svg {
  width: 100%;
  height: 100%;
}

.svgWrapper {
  display: flex;
  margin-top: auto;
}
