.wrapper {
  margin-bottom: 8.5px;
  display: grid;
  grid-gap: 10px;
  min-width: 200px;
}

.tableRow {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1.5fr 1fr 1fr 25px;
}

.tableRow:not(.tableHeaderRow) {
  margin-bottom: 8.5px;
}

.tableRow input {
  margin-bottom: 0;
}

.tableHeaderRow {
  font-weight: 600;
  font-size: 12px;
  border: none !important;
  color: var(--midnight);
}

.delete {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.delete:not(.disabled):hover svg path {
  fill: var(--slate);
}

.disabled {
  cursor: not-allowed !important;
}
