.container {
  flex: 1;
  display: flex;
  align-items: center;
  height: 30px;
}
.container.disabled {
  opacity: 0.5;
}

.input {
  border: none;
  height: 100%;
  flex: 1;
  max-width: 300px;
  background-color: transparent;
}
.input::placeholder {
  color: var(--smoke);
}
