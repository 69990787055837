/* ./ExternalProjectBulkImport.tsx */

.modalFooter {
  /* override ModalFooter styles */
  justify-content: space-between;
}

.selectedCount {
  display: flex;
  align-items: center;
  gap: 8px;

  color: var(--slate);
  font-weight: 600;
}

/* ./ExtProjectListTable.tsx */

.tableContainer {
  width: 100%;
  margin: 24px auto;
  border-collapse: collapse;
  overflow: auto;
}

.tableHeader {
  color: var(--midnight-grey);
  font-weight: 600;
  border-bottom: 1px solid var(--cloud);
}

.tableRow {
  border-bottom: 1px solid var(--cloud);
}

.tableContainer td,
.tableContainer th {
  padding: 12px 0;
  text-align: left;
}

.tableHeader th {
  min-width: 50px;
}

.tableHeader th,
.tableRow td {
  width: auto;
  white-space: nowrap;
  padding-right: 12px;
}
