.wrapper {
  height: 100%;
}

.wrapper :global(.bp5-tab) {
  padding-bottom: 18px;
}

.wrapper > :global(.bp5-tab-panel[aria-hidden="false"]) {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.toggleDetails {
  display: flex;
  cursor: pointer;
}

.toggleDetails:hover,
.toggleDetails:hover svg {
  color: var(--runn-blue);
  fill: var(--runn-blue);
}

.details svg {
  margin-left: 4px;
  color: var(--runn-blue);
  fill: var(--runn-blue);
}
