.callout {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  background-color: var(--link-light);
  border-radius: 5px;
  padding: var(--spacing-medium);
  font-style: italic;
  color: var(--midnight);
}
