.icon {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--runn-blue);
  user-select: none;
}

.icon svg {
  width: 100%;
  height: 100%;
  padding: 5px;
}

.icon.small svg {
  padding: 3px;
}

.deselected,
.deselected:hover {
  background-color: var(--smoke);
}

.deselected .emojiIcon,
.deselected:hover .emojiIcon {
  background-color: var(--smoke) !important;
}

.noSymbol {
  min-width: 15px;
  min-height: 15px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--runn-blue);
}

.emojiIcon {
  height: 28px;
  width: 28px;
  font-size: 18px;
  background-color: var(--runn-blue);
  margin-top: 4px;
}

.emojiIcon:hover {
  border-color: var(--smoke);
}

.emojiIcon :global(.emoji-remove) svg {
  padding: 0px !important;
}
