.bell {
  position: relative;
  cursor: pointer;
}

.unseenCountDot {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  background-color: var(--runn-blue);
  border: 1.5px solid white;
  border-radius: 50%;
  z-index: 1;
}
