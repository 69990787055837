.container {
  padding: 24px;
  max-height: calc(100vh - 330px);
  overflow-y: auto;
}

.commentBox {
  display: flex;
  align-items: flex-end;
  border: 1px solid var(--shadow);
  border-radius: 4px;
  padding: 12px;
  position: absolute;
  bottom: 24px;
  width: 355px;
  margin-left: 24px;
}

.commentTextArea {
  height: 64px;
  border: none;
  outline: none;
  resize: none;
  flex: 1;
  scrollbar-width: none;
}

.commentTextArea::placeholder {
  color: var(--smoke);
}

.sendBtn {
  height: 20px;
  width: 20px;
  border-radius: 4px;
  padding: 3.75px;
}
