.parentContainer {
  height: 100%;
  display: flex;
  align-items: center;
}

.badge {
  border-radius: 30px;

  padding: 5px 20px;
  margin: 0 10px;

  font-size: 12px;

  cursor: pointer;
  transition: opacity 0.3s;
}

.badge.visible {
  opacity: 1;
}

.badge.invisible {
  opacity: 0;
}

.badge.invisible:hover {
  opacity: 0.1;
}

.actionItem,
.userInfoItem {
  list-style-type: none;
}

.drawer :global(.bp5-drawer-header) {
  box-shadow: none;
  border-bottom: 1px solid var(--winter);
}
