.wrapper {
  font-size: 13px;
  display: flex;
  align-items: center;
}

.dropdownHeader {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1px;
  padding: 10px 15px;
  text-transform: uppercase;
  color: var(--midnight);
}

.dropdownInput {
  display: flex;
  column-gap: "var(--spacing-tiny)";
  align-items: center;
}

.dropdownInput path {
  fill: var(--shadow);
}

.dropdownInput input {
  outline: none;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.13px;
  border: none;
  outline: none;
  color: var(--midnight);
  font-size: 13px;
}

.dropdownInput ::placeholder {
  color: var(--shadow);
}

.dropdownTarget {
  font-size: 13px;
  display: flex;
}

.roleTooltip svg {
  height: 14px;
  width: 14px;
  display: flex;
  margin-right: 5px;
}

.iconWrapper {
  margin-left: 4px;
  margin-top: 2px;
  display: flex;
}

.dropdownTarget:hover {
  color: var(--runn-blue);
}

.dropdownTarget:hover svg {
  fill: var(--runn-blue);
}
