.badge {
  background-color: var(--snow);
  border-radius: 2px;
  padding: 0 4px;
  margin-left: 8px;
  margin-right: 2px;
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
  border-collapse: collapse;
}
