.checkboxContainer {
  position: absolute;
  z-index: 1;
  right: 15px;
  top: 16px;
}

.quickAdd {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
}

.quickAdd:hover svg path {
  fill: var(--runn-blue);
}

.quickAdd svg {
  height: 28px;
  width: 28px;
}
