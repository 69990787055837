.tagInputWrapper input {
  background: none;
  font-weight: 500;
  color: var(--midnight);
  padding: 0;
  border: none;
  height: 100%;
}

.tagInputWrapper input::placeholder {
  color: var(--shadow);
}

.addNewTagIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  width: 26px;
  height: 26px;
  margin-top: 5px;
  border-radius: 3px;
}

.addNewTagIcon:hover {
  background-color: var(--cloud);
  cursor: pointer;
}

.addNewTagIcon svg {
  width: 12px;
  height: 12px;
}
