.timelineWeekendExpanders {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
}

.weekendWrapper {
  z-index: 1;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.line {
  width: 1px;
  background-color: var(--winter);
  height: 100%;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 8px;
  background: rgb(248 249 250/80%);
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
}

.expander {
  height: 100%;
  display: flex;
  align-items: center;
}

.expander.mirrored {
  flex-direction: row-reverse;
}

.expander.mirrored .arrow {
  transform: scaleX(-1);
}

.weekend {
  display: flex;
  cursor: pointer;
}

.weekend:hover .arrow svg path,
.isHighlighted .arrow svg path {
  fill: var(--runn-blue);
}

.weekend:hover .line,
.isHighlighted .line {
  background-color: var(--runn-blue);
}

.weekend.isExpanded:hover,
.isHighlighted.isExpanded {
  background: rgb(64 87 223 / 10%);
}

.isExpanded {
  background: rgb(255 255 255 / 30%);
}

.isExpanded .arrow {
  background-color: transparent;
}

.isExpanded .expanderContainer {
  flex-direction: inherit;
}

.expanderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.tooltip {
  width: 100%;
}
