.container {
  padding: 20px;
  text-align: center;
}

.heading {
  font-weight: 600;
  color: var(--midnight);
  font-size: 16px;
  line-height: 2;
}

.paragraph {
  color: var(--slate);
  line-height: 2;
  font-size: 14px;
  line-height: 2;
}

.clearFiltersButton {
  background: none;
  border: none;
  color: var(--runn-blue);
  font-weight: 600;
  margin-top: 8px;
  cursor: pointer;
}
.clearFiltersButton:hover {
  color: var(--runn-blue-hover);
}

.bold {
  font-weight: 600;
}

.link {
  color: var(--runn-blue);
  font-weight: 600;
}
.link:hover {
  color: var(--midnight);
  cursor: pointer;
}
