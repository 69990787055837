.root {
  display: flex;
  margin-top: var(--spacing-xs);
  gap: var(--spacing-xs);
  font-style: italic;
  color: var(--slate);
}

.line {
  border-left: 2px solid var(--runn-blue);
}

.danger {
  border-left: 2px solid var(--alert);
}
