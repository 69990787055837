.container {
  display: flex;
  background: var(--white);
  border-radius: 5px;
  padding: 0;
}

.border {
  background: var(--info);
  height: auto;
  width: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
}

.icon svg {
  fill: var(--info) !important;
  height: 40px;
  width: 40px;
}

.content {
  flex-grow: 1;
  padding: 20px 0;
}

.container h2 {
  font-size: 20px;
  font-weight: bolder;
  margin: 0 0 10px 0;
}

.noCreditCard,
.trialButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.noCreditCard {
  color: var(--slate);
  padding: 0 5px;
}

.trialButton {
  padding: 0 20px 0 5px;
}
