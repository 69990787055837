.phasePicker {
  flex-grow: 1;
  margin-right: 11px;
  margin-left: 11px;
}

.phaseOption {
  display: flex;
  align-items: center;
}

.phaseOptionText {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.phaseIcon {
  min-width: 15px;
  margin-right: 8px;
}
