.curtain {
  display: flex;
  position: absolute;
  height: 100%;
  z-index: 0;
}

.fullDayLegacy {
  background-color: rgba(112, 117, 147, 0.12);
}

.fullDay {
  background-color: var(--cloud);
  z-index: 8;
}

.borderContainer {
  position: relative;
  height: 100%;
  width: 100%;
}

.border {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: var(--winter);
}

.boldBorder {
  width: 2px;
}

.partialDay {
  background-color: #fff8e7;
  mix-blend-mode: multiply;
  pointer-events: none;
}
