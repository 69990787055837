.toggle {
  padding: 8px 0px 5px 15px;
}

.dropdownContainer {
  min-width: 240px;
}

.menuItemContainer {
  display: grid;
  grid-row-gap: 24px;
  border-left: 1px solid var(--winter);
  margin: 16px 0 16px 16px;
}

.menuItemHeading {
  font-size: 13px;
  font-weight: 700;
  color: var(--midnight);
  display: inline-block;
  margin: 0 0 8px 12px;
}

.menuItem {
  height: 32px;
}

.moreDetailsToggle {
  margin-left: 16px;
  margin-bottom: 16px;
}

.showWeekendsToggle {
  user-select: none;
  padding: var(--spacing-xxs) 0px;
}
