.modeWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 15;
  bottom: 10px;
  padding: 8px 5px;
  border-radius: 3px;
  color: #fff;
  background-color: var(--midnight);
  box-shadow: 3px 4px 5px -1px rgba(0, 0, 0, 0.3);
}

.sidePanelOpen {
  max-width: calc(90% - var(--side-panel));
}

.modeWrapper > * {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  line-height: 20px;
}

.modeTitle {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  border-right: 1px solid var(--slate);
}

.modeMessage {
  font-size: 14px;
  border-right: 1px solid var(--slate);
}

.modeMessage b {
  font-weight: 500;
}
