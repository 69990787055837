.wrapper {
  margin-bottom: 8.5px;
  display: grid;
  grid-gap: 10px;
}

.tableRow {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1.2fr repeat(3, 1fr) 0.8fr 1fr 20px;
}

.tableRow.permissions {
  grid-template-columns: 1.2fr repeat(3, 1fr) 0.8fr 1fr 20px;
}

.tableRow.permissions > * {
  overflow: hidden;
}

.tableRow:not(.tableHeaderRow) {
  margin-bottom: 8.5px;
}

.tableRow Input {
  margin-bottom: 0;
}

.tableHeaderRow {
  font-size: 12px;
  color: var(--midnight);
  font-weight: 600;
}

.tableHeaderRow > div {
  display: flex;
}

.tableHeaderRow > div > * {
  align-self: flex-end;
}

.delete {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.delete:not(.disabled):hover svg path {
  fill: var(--slate);
}

.disabled {
  cursor: not-allowed !important;
}

.linkContainer:hover svg {
  fill: var(--runn-blue-hover);
  color: var(--runn-blue-hover);
}

.link {
  display: flex;
  fill: var(--runn-blue);
  font-size: 13px;
}

.error {
  color: var(--alert-dark);
}
