.container {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  background-color: #fcf8ff;
}

.noGap {
  gap: 0;
}

.title {
  background: var(--runn-purple-gradient);
  -webkit-background-clip: text;
  padding: 9px 15px !important;
  background-clip: text;
  color: transparent;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.12px;
}

.subtitle {
  padding: 9px 15px;
}

.selectPlanButton {
  display: flex;
  padding: 8px 0 !important;
  margin-top: 5px;
  margin-bottom: 10px;
  align-self: stretch;
  border-radius: 3px;
  border: var(--runn-purple) 1px solid !important;
  background: var(--runn-purple-gradient) !important;
  background-clip: text !important;
  color: transparent !important;
}

.roundButton {
  border-radius: 30px;
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 9px;
}

.subtitle {
  white-space: normal;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
}
