.plannerHeader {
  grid-column: 1 / -1;
  position: relative;
  z-index: 16;
  border-bottom: 1px solid var(--cloud);
}

.filterControls {
  background-color: #fff;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}
