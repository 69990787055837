.icon {
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.down {
  transform: rotate(90deg);
}

.right {
  transform: rotate(0deg);
}

.up {
  transform: rotate(-90deg);
}

.left {
  transform: rotate(180deg);
}
