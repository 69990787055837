.numericInput {
  width: 70px;
}

.numericInput input {
  height: 40px;
}

.checkbox {
  margin-top: var(--spacing-small);
}

.checkbox > label {
  margin-bottom: 0;
}
