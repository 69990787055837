.container {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  color: var(--shadow);
}

.container[data-type="project"] {
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-default);
}

.container[data-type="person"] {
  margin-top: var(--spacing-default);
  margin-bottom: initial;
}
