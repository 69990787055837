.container {
  max-width: 800px;
}

.dateContainer {
  display: flex;
  padding: 10px;
  gap: 8px;
}

.datePickerContainer {
  display: flex;
  flex-direction: column;
}

.datePickerContainer p {
  margin: 0 0 0 15px;
  font-weight: 600;
  color: var(--midnight);
}

.select {
  flex: 1, 0, auto;
}

.date {
  display: flex;
  column-gap: 8px;
  flex: 3, 1, auto;
}

.emptyStateWrapper {
  color: var(--shadow);
  font-size: 13px;
  padding: 12px 10px;
}

.search {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--winter);
  height: 40px;
  padding-left: 8px;
}
.searchIcon {
  margin-left: 8px;
}
.searchInput {
  border: none;
  flex: 1;
  height: 100%;
}
.resetSearchButton {
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
  margin-right: 8px;
}
.search.hasSearchValue .resetSearchButton {
  opacity: 1;
}
.searchCheckbox {
  margin-right: 12px;
}

.list {
  max-height: 300px;
  overflow-y: auto;
}

.doneButton {
  height: 40px;
  width: 100%;
  border: none;
  border-top: 1px solid var(--winter);
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: var(--runn-blue);
}
.doneButton:hover {
  background: var(--ice);
}
.doneButton:disabled {
  opacity: 0.5;
}

.label {
  background-color: var(--tentative);
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  color: #fff;
  padding: 2.5px 6px;
  margin-right: 10px;
}

.buttonText {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
