.container {
  padding: 0 20px;
  width: 1000px;
  overflow-y: auto;
  max-height: calc(100vh - 150px);
}

.example {
  background: var(--cloud);
  padding: 8px;
  margin: 20px 30px 10px 30px;
  border-radius: 5px;
}

.table {
  padding: 0 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}
