.capacityAmount {
  height: 35px;
  background-color: var(--placeholder-background);
  align-self: flex-end;
  border: 1px dashed var(--placeholder-border);
  color: var(--runn-midnight-blue);
  font-weight: 600;
  font-size: 12px;
  align-items: flex-end;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  border-right: none;
}

.barText {
  padding: 6px 8px;
}

.inHeadingBar {
  height: 100%;
  background-color: var(--snow);
  color: var(--slate);
  border: 1px solid var(--cloud);
  border-top: 0;
  border-bottom: 0;
}

.noAssignments {
  background-color: var(--ice);
  border: none;
}

.noAssignments.inHeadingBar {
  background-color: transparent;
}

.borderRight {
  border-right: 1px dashed var(--placeholder-border);
}

.collapsedWeekend {
  position: absolute;
  z-index: 2;
  padding: 0;
  overflow: hidden;
  border-right: 1px dashed var(--placeholder-border);
}

.inHeadingBar.borderRight,
.inHeadingBar.collapsedWeekend {
  border-right: 1px solid var(--winter);
}
