.wrapper section {
  margin-top: var(--spacing-xl);
}

.header {
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid var(--winter);
  margin-bottom: var(--spacing-small);
  padding-bottom: var(--spacing-default);
}

.inputs {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  grid-gap: var(--spacing-small);
}

.maxLimit {
  position: absolute;
  bottom: -8px;
  font-size: 11px;
  font-weight: 500;
  color: var(--slate);
}

.infoContainer {
  display: flex;
  align-items: center;
  color: var(--slate);
  font-size: 13px;
  margin-right: auto;
}

.infoContainer p {
  margin: 0 0 0 5px;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
