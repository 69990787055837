.savedFilterList {
  max-height: 570px; /* 15 card items */
  overflow: auto;
}

.icon {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.icon svg {
  max-width: 16px;
  max-height: 16px;
}

.showAllText {
  color: var(--shadow);
}

.removeButton {
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 15px;
}

.removeButton svg path {
  fill: var(--shadow);
}

.removeButton:hover svg path {
  fill: var(--midnight);
}

.filterItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.savedFilterMenuItem {
  padding: 15px !important;
}
