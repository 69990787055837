.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.timeOffSummary {
  height: 100%;
  display: flex;
}

.collapse {
  transition: height 500ms;
}

.virtualContainer {
  scrollbar-gutter: stable;
  overflow: hidden scroll !important;
}

.timeOffSummary a {
  color: var(--midnight);
}

.timeOffSummary a:hover {
  color: var(--runn-blue);
}

.disabled {
  background-color: var(--ice);
  pointer-events: none;
  opacity: 0.5;
}

.calendarBackground {
  display: flex;
  flex: 1;
  height: 100%;
  z-index: -1;
  overflow: hidden scroll;
}
