.link,
.link:hover,
.link:focus {
  color: var(--link) !important;
  padding: 0 !important;
  background: none !important;
  border: none !important;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: -0.01em;
}

.link:hover,
.link:focus {
  color: var(--link-dark) !important;
}

.link:focus-visible {
  text-decoration: underline;
}
