.currencyInputWrapper {
  display: flex;
  height: 100%;
}

.currency {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: var(--slate);
  background-color: var(--ice);
  border: 1px solid var(--smoke);
  border-right: none;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.currencyInputWrapper :global(.bp5-numeric-input .bp5-input) {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  height: 100%;
  text-align: right;
}

.rateUnit {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: var(--slate);
  background-color: var(--ice);
  border: 1px solid var(--smoke);
  border-left: none;
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  min-width: 60px;
}

.hideArrows :global(.bp5-numeric-input .bp5-input) {
  border-top-right-radius: inherit !important;
  border-bottom-right-radius: inherit !important;
}

.disabled :global(.bp5-numeric-input .bp5-input) {
  background: var(--ice) !important;
}

.disabled .currency,
.disabled .rateUnit {
  color: rgba(92, 112, 128, 0.6);
}
