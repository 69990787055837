.suggestedPerson {
  display: flex;
  align-items: center;
  padding: 2px 9px 2px 2px;
  gap: 8px;
  border-radius: 2px 0px 0px 0px;
}

.suggestedPerson__name {
  color: var(--midnight);
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: left;
  margin: 0;
}
