.inputWrapper {
  display: flex;
  width: 100%;
}

.inputWrapper :global(.bp5-numeric-input) {
  display: block;
}

.inputWrapper :global(.bp5-input) {
  box-shadow: none;
  border: 1px solid var(--smoke);
}

.inputWrapper :global(.bp5-input:focus) {
  border-color: var(--runn-blue);
}

.inputWrapper :global(.bp5-input-group) {
  width: 100%;
  height: 100%;
}

.inputWrapper :global(.bp5-input::placeholder) {
  text-align: left;
}

.error input,
.error input:focus {
  border-color: var(--alert) !important;
}

.inputArrows {
  border: 1px solid var(--smoke);
  border-top-right-radius: var(--rounded-sm);
  border-bottom-right-radius: var(--rounded-sm);
  min-width: 30px;
  display: flex;
  flex-direction: column;
  border-left: none;
}

.inputArrows > button {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background: white;
  border-radius: 0;
}

.inputArrows > button:active {
  background-color: #d8e1e8;
  box-shadow:
    inset 0 0 1px 1px rgba(16, 22, 26, 0.1),
    inset 0 1px 2px rgba(16, 22, 26, 0.1);
}

.inputArrows > button:focus-visible {
  background-color: var(--runn-blue);
}

.inputArrows > button:focus-visible svg path {
  fill: #fff;
}

.inputArrows > button:active svg {
  fill: var(--midnight);
}

.inputArrows > button:first-child {
  border-bottom: 1px solid var(--smoke);
}

.inputWrapper input {
  border-right: 0;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.disabled {
  cursor: not-allowed;
}

.disabled > div > span {
  cursor: not-allowed;
}
