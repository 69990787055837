.wrapper {
  margin-top: 20px;
  display: grid;
  grid-gap: 10px;
}

.financialWrapper {
  display: grid;
  grid-gap: 10px;
}

.financialWrapper p {
  text-align: left;
  color: var(--midnight);
}

.tableRow {
  display: grid;
  align-items: center;
  grid-gap: 10px;
}

.onboardingProjects,
.onboardingPeople,
.onboardingFinancials {
  overflow-y: auto;
  max-height: calc(90vh - 100px);
}

.onboardingProjects .tableRow {
  grid-template-columns: 1fr 1fr 20px;
}

.onboardingPeople .tableRow {
  grid-template-columns: 1fr 1fr 1.5fr 1.5fr 20px;
}

.onboardingFinancials {
  min-width: 600px;
}

.onboardingFinancials .tableRow {
  grid-template-columns: 1fr minmax(180px, 1fr);
  border-top: 1px solid var(--snow);
  padding-top: 10px;
}

.tableHeaderRow {
  font-weight: 600;
  font-size: 12px;
  color: var(--midnight);
  border: none !important;
}

.onboardingTextContainer {
  padding: 30px 30px 0 30px;
}

.subtitle {
  color: var(--slate);
  line-height: 1.5em;
  margin-bottom: 10px;
  font-size: 16px;
}

.actions {
  margin-top: 25px;
  text-align: center;
}

.seperator {
  margin-top: 40px;
  border: 0;
  height: 1px;
  background: #b8bbc9;
  background-image: linear-gradient(to right, #fff, #b8bbc9, #fff);
}

.orSeperator {
  margin-top: -21px;
  justify-content: center;
  display: flex;
  font-size: 1.4em;
  color: var(--slate);
}

.headerText p,
.headerText li {
  text-align: left;
  color: var(--midnight);
  line-height: 1.5rem;
}

.helpTooltip {
  border-bottom: 1px dotted black;
  cursor: help;
}

.delete {
  cursor: pointer;
}

.delete:hover svg path {
  fill: var(--slate);
}

.disabled {
  pointer-events: none;
  cursor: not-allowed !important;
}

.cellSmall {
  width: 150px;
}

.tooltipMessage {
  max-width: 200px;
}

.radioGroup {
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 27px;
  margin-bottom: 20px;
}

.radioGroup :global(.bp5-radio) {
  font-weight: 600 !important;
  font-size: 13px;
}

.iframeWrapper {
  display: flex;
  justify-content: center;
  padding: 30px 30px 0 30px;
  min-height: 395px;
  width: 100%;
}

.heading {
  font-size: 28px;
  margin-bottom: 10px;
  font-weight: 600;
}

.subheading {
  font-size: 20px;
  color: var(--slate);
  line-height: 1.5em;
  margin-bottom: 10px;
}

.onboardingStep {
  margin-right: 30px;
  font-size: 12px;
  color: var(--slate);
}
