.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tabContainer {
  padding: 0;
}

.placeholderTabs :global(.bp5-tab-list.bp5-large) {
  background-color: #fff !important;
  border-bottom: 1px solid var(--winter);
}

.placeholderTabs :global(.bp5-tab) {
  padding-bottom: 14px !important;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1em;
}

.placeholderTabs :global(.bp5-tab[aria-selected="true"]) {
  color: var(--runn-blue);
}

.requestStatusContainer {
  display: flex;
  flex-direction: row;
  background-color: var(--ice);
  padding: var(--spacing-large);
  border-bottom: 1px solid var(--winter);
  align-items: center;
}

.respondContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.requestBody {
  padding: 0;
}

.tripleDots {
  margin-left: var(--spacing-xs);
  margin-top: var(--spacing-xs);
}

.statusTextContainer {
  margin-left: var(--spacing-small);
  margin-right: var(--spacing-xxs);
}

.requestForm {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

.requestSubTitle {
  font-size: 13px;
  margin: 0;
}

.detailsContainer {
  height: 100%;
  padding: var(--spacing-large);
}

.detailsTitle {
  font-size: 13px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: -0.01em;
  margin: 0;
  padding-bottom: var(--spacing-xxs);
  color: var(--midnight);
}

.detailsValue {
  margin-bottom: var(--spacing-default);
  font-size: 13px;
  overflow-wrap: break-word;
}

.detailsValue > .bp5-button.bp5-intent-primary.bp5-outlined {
  min-height: auto;
}

.skillsContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: var(--spacing-default);
  min-height: 40px;
}

.skillsContainer > *:not(:last-child) {
  margin-right: var(--spacing-xs);
}

.tagsContainer {
  margin-bottom: var(--spacing-default);
}

.moreDetailsContainer {
  display: flex;
  background-color: rgba(var(--sea-blue_rgb), 0.05);
  padding: var(--spacing-small) var(--spacing-default);
  border-radius: 3px;
  align-items: center;
}

.moreDetailsTextContainer {
  padding-left: var(--spacing-default);
  font-size: 13px;
}

.moreDetailsText {
  color: var(--midnight);
  margin: 0;
}

.title {
  font-size: 18px;
  font-weight: 600;
  padding-left: var(--spacing-large);
  padding-top: var(--spacing-large);
  border-top: 1px solid var(--winter);
  margin-bottom: 0;
}

.requestTitleContainer {
  border-top: 1px solid var(--winter);
  display: flex;
  justify-content: space-between;
}

.requestDetailsTitle {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--slate);
  letter-spacing: 0.1em;
}

.requestComment {
  padding: var(--spacing-large);
  padding-bottom: 0;
}

.commentTextArea {
  height: 128px;
  border: 1px solid var(--shadow);
  border-radius: 4px;
  padding: var(--spacing-small);
  outline: none;
  resize: none;
  width: 100%;
  color: var(--midnight);
}

.actionBtn:global(.bp5-button.bp5-intent-primary.bp5-outlined):hover svg path,
.moreIcon:global(.bp5-icon.bp5-icon-more):hover svg path {
  fill: var(--runn-blue) !important;
}

.suggestedPeopleList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-block-start: var(--spacing-xs);
  margin-block-end: var(--spacing-xs);
}
