.container {
  padding: 0 20px 20px 20px;
  width: 500px;
  overflow-y: auto;
  max-height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.actions {
  display: flex;
  flex-direction: column;
}

.actions > div {
  padding: 12px 0 12px 0;
  border-bottom: 0.5px solid #e0e0e0;
}

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalBody {
  background-color: white;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.log {
  white-space: nowrap;
  font-size: 16px;
}

.bell {
  display: inline-block;
  animation: ring 1s infinite;
  margin-right: 4px;
}

.step {
  font-weight: bold;
  font-size: 12px;
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  10% {
    transform: rotate(15deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  30% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
