.warning {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #fef9eb;
  border-radius: 2px;
  margin-bottom: 20px;
}

.message {
  padding-left: 10px;
  font-size: 12px;
  line-height: 18px;
}
