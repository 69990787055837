.container {
  max-height: 50vh;
  overflow-y: auto;
  scrollbar-color: rgba(0, 0, 0, 0.5) var(--ice);
  scrollbar-width: thin;
}

.heading {
  justify-content: space-between;
}

.toggleIcon {
  width: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggleIcon svg {
  height: 100%;
  width: 100%;
}

.toggleIcon:hover svg path {
  fill: var(--runn-blue);
}
