.targetButton {
  position: relative;
  height: 30px;
  display: flex;
  background: #fff;
  border: 1px solid var(--winter);
  margin-left: 8px;
  color: var(--midnight);
  border-radius: 3px;
  overflow: hidden;
  padding: 0;
}

.targetButton.empty,
.targetButton.empty:hover:not(.isDropdownOpen) {
  color: var(--alert-dark);
  border-color: var(--alert-dark);
  background: #faebeb;
}
.targetButton.empty .targetIcon svg path,
.targetButton.empty .targetIcon svg rect,
.targetButton.empty:hover:not(.isDropdownOpen) .targetIcon svg path,
.targetButton.empty:hover:not(.isDropdownOpen) .targetIcon svg rect {
  fill: var(--alert-dark);
}

.targetButton:disabled {
  opacity: 0.5;
}

.targetButton:not(:disabled):hover,
.targetButton.isDropdownOpen {
  background: var(--link-light);
  border-color: var(--runn-blue);
  color: var(--runn-blue);
}

.targetButtonText {
  display: flex;
  align-items: center;
  height: 29px;
  padding: 0 8px 0 8px;
}

.targetIcon {
  margin-right: 5px;
}
.targetIcon svg {
  display: flex;
}
.targetIcon svg path,
.targetIcon svg rect {
  fill: var(--midnight);
}

.targetButton:not(:disabled):hover .targetIcon svg path,
.targetButton:not(:disabled):hover .targetIcon svg rect,
.targetButton.isDropdownOpen .targetIcon svg path,
.targetButton.isDropdownOpen .targetIcon svg rect {
  fill: var(--runn-blue);
}

.targetLabel {
  font-weight: 600;
  margin-right: 6px;
  white-space: nowrap;
}

.targetSelected {
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.targetSelectedCount {
  margin-left: 4px;
}

.removeButtonTooltip {
  position: absolute;
  right: 0;
  height: 28px;
}
.removeButton {
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, rgba(240, 242, 254, 0.3) 0%, #f0f2fe 40%);
  padding: 6px 8px 0 10px;
  color: var(--runn-blue);
  border: none;
  align-items: center;
  border-radius: 3px;
  display: none;
  cursor: pointer;
}
.targetButton.empty:not(.isDropdownOpen) .removeButton {
  background: linear-gradient(90deg, #faebeb00 0%, #faebeb 70%);
}
.targetButton.empty:not(.isDropdownOpen) .removeButton svg path {
  fill: var(--alert-dark);
}
.targetButton:hover .removeButton,
.targetButton.isDropdownOpen .removeButton {
  display: block;
}

.tooltip {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tooltipItem {
  color: white;
  border-radius: 3px;
  background: #636882;
  padding: 3px 6px;
  margin: 2px;
}

.targetTag {
  background-color: var(--snow);
  border-radius: 2px;
  padding: 0 4px;
  margin-right: 2px;
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
  border-collapse: collapse;
}

.tooltipItem {
  display: flex;

  & .targetTag {
    background-color: color-mix(in srgb, #636882, #000000 20%);
    background-color: #4f5368; /* fallback */
  }
}
