.checkbox {
  position: relative;
  display: flex;
  align-items: center;
}

.checkboxInput {
  appearance: none;
  height: 20px;
  width: 20px;
  border: 1px solid var(--smoke);
  border-radius: var(--rounded);
  background: var(--ice);
}

.checkboxInput:checked {
  background: var(--runn-blue);
  border: none;
}

.checkboxTick {
  position: absolute;
  top: 4px;
  left: 4px;
  display: none;
  pointer-events: none;
}

.checkboxInput:checked ~ .checkboxTick {
  display: block;
}
