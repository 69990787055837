.viewExternalProjectLink {
  font-weight: 600;
  font-size: 13px;
  color: var(--slate);

  display: flex;
  align-items: center;
  gap: 8px;
}

.viewExternalProjectLink:hover {
  color: var(--midnight);
}

.viewExternalProjectLink span {
  padding: 0 7px;
}

.viewExternalProjectLink svg path {
  fill: var(--slate);
}

.viewExternalProjectLink:hover svg path {
  fill: var(--midnight);
}
