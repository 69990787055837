.modal {
  width: 550px;
}

.header {
  background-color: white;
}

.tabs :global(.bp5-tab-list.bp5-large) {
  background-color: white;
  text-transform: uppercase;
  border-top: 1px solid var(--cloud);
  border-bottom: 1px solid var(--cloud);
  height: 49px;
  padding: 20px 0 0 20px;
}

.tabs :global(.bp5-tab) {
  font-size: 12px !important;
  font-weight: 600;
  letter-spacing: 0.6px;
  line-height: normal;
  padding-bottom: 15px;
}

.tabs :global(.bp5-tab[aria-selected="true"]),
.tabs :global(.bp5-tab:not([aria-disabled="true"]):hover),
.tabs :global(.bp5-tab-list:not(.bp5-large) > .bp5-tab[aria-selected="true"]),
.tabs
  :global(
    .bp5-tab-list:not(.bp5-large) > .bp5-tab[aria-selected="true"]:hover
  ) {
  color: var(--runn-blue);
}

.footerText {
  margin-right: auto;
  text-decoration: underline;
}

.launchIcon {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 1px;
  margin-left: var(--spacing-xxs);
}
