.tooltip:global(.bp5-popover-target) {
  display: inline-block;
}

.observed::after {
  margin-left: 2px;
  content: "*";
}

.checkBox > label {
  margin: 0;
}

.table thead {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--cloud);
  color: var(--shadow) !important;
}

.table tbody {
  font-size: 13px;
  color: var(--midnight);
}

.table th {
  padding: 5px 5px 15px 5px;
  color: var(--midnight);
  font-weight: 700;
}

.table th:first-of-type {
  padding: 5px 0 15px 0;
}

.table tr td:first-of-type {
  padding: 5px 0 5px 0;
}

.table th:nth-of-type(2) {
  width: 30%;
}
