.container {
  --height: 30px;
  --background: #fff;
  --border-color: var(--winter);

  display: flex;

  height: var(--height);
  border-radius: var(--height);
  padding: 0 6px;

  align-items: center;
  justify-content: center;

  background: var(--background);
  border: 1px solid var(--border-color);
}
