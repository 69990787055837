.bold {
  font-weight: 600;
}

.normal {
  font-weight: 400;
}

.icon {
  cursor: auto !important;
}

.comment {
  margin-top: 8px;
  max-width: 800px;
}
