.splitScreenMessage > div {
  display: flex;
  margin: 0 var(--spacing-xs);
}

.splitScreenMessage > div > * {
  margin-right: var(--spacing-xxs);
}

.pastMinutesWrapper {
  color: var(--warning-light);
  font-size: 14px;
  align-items: center;
}

.isSidePanelOpen {
  display: inline !important;
}
