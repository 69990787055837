.wrapper {
  margin-bottom: 20px;
}

.wrapper :global(.bp5-label) {
  font-size: 12px;
  color: var(--midnight);
  font-weight: 600;
}

.wrapper :global(.bp5-control) {
  font-size: 13px;
}

.permissions {
  font-size: 13px;
}

.roleCostContainer {
  font-size: 12px;
  font-weight: 600;
  margin-top: var(--spacing-xxs);
  color: var(--link);
  cursor: pointer;
}

.roleCostContainer:hover,
.roleCostContainer:focus {
  color: var(--link-dark);
}
